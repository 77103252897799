import { REST_URLS } from "../../common-utilities/utils/end-points/invoice-tracking";
import { HTTP_METHODS, invokeApi } from "./http-service";
import { clearOfflineData, setOfflineData } from "./offline-services";
import { INTRANETHOSTNAME } from "../../common-utilities/utils/end-points";
import { appSlugs } from "../../../utils/constants";
import {
  endPointsForProject,
  getProjectEndPoint,
} from "../../../routes/endpoints";
export const redirectToIntranetPortal = (tokenKey) => {
  clearOfflineData("user", tokenKey);
  clearOfflineData("tokens", tokenKey);
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const getUserDetailsFromIntranet = (
  Authorization,
  customLogic,
  tokens,
  tokenKey,
  slug
) => {
  invokeApi(
    HTTP_METHODS.POST,
    `${INTRANETHOSTNAME}${REST_URLS.INTRANET_LOGIN}`,
    null,
    null,
    Authorization,
    slug,
    tokenKey
  )
    .then((res) => {
      if (res?.message) {
        redirectToIntranetPortal(tokenKey);
        return;
      }
      if (tokens) {
        setOfflineData("tokens", tokens, tokenKey);
      }
      const role = res?.role?.toUpperCase();
      setOfflineData("user", { ...res, role }, tokenKey);
      if (typeof customLogic === "function") {
        customLogic(res);
      }
    })
    .catch(() => {
      redirectToIntranetPortal(tokenKey);
    });
};

export const getFileNameType = (url) => {
  if (url) {
    const urlSplits = url.split("/");
    if (urlSplits.length > 0) {
      const lastSplits = urlSplits[urlSplits.length - 1].split(".");
      return lastSplits[lastSplits.length - 1];
    }
  }

  return "";
};

export const getFileName = (url) => {
  if (url) {
    const urlSplits = url.split("/");
    if (urlSplits.length > 0) {
      return urlSplits[urlSplits.length - 1];
    }
  }

  return "";
};

export const downloadFile = (url) => {
  window.open(url, "_blank").focus();
};

export const downloadFileFromUrl = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = "";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const getRedirectPointBySlug = (slug, route = "") => {
  return `/${getProjectEndPoint(endPointsForProject.plReport)}`;
};

export const getCorrectDay = (date) => {
  if (!date || new Date(date) === "Invalid Date" || typeof day === "object") {
    return;
  }
  const providedDate = new Date(date);
  providedDate.setSeconds(providedDate.getSeconds() + 30);

  return providedDate.toLocaleString("en-US", { dateStyle: "short" });
};
