import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import { downloadInvoiceData } from "./utils";
import FileUpload from "../../../../common-utilities/core/file-uploader";
import DownloadTemplateButton from "../../master-data/common-components/upload-template";
import { uploadTemplates } from "../../master-data/constants";
import { setProperty } from "../../../../common-utilities/utils/misc";
import { isUploadAllowed } from "../../../utils/common-utils";
import PoFilters from "../../purchase-order/po-filters";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../common-utilities/utils/time";
import { downloadFileFromUrl } from "../../../../electricity-consumption/utils/common-utils";
const InvoiceDataList = ({ status, usedIn }) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
    invoiceType: usedIn === "GRN" ? "grn_invoice" : "advance_invoice",
  });

  useEffect(() => {
    if (status) {
      loadData(filters);
    }
  }, [status]);

  const loadData = (filters, download) => {
    let params = {
      ...filters,
    };
    if (status !== "all") {
      params.status = status;
    }
    if (searchKey) {
      params.searchKey = searchKey;
    }
    if (usedIn === "GRN" && download) {
      params.download = true;
    }
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.INVOICE_DATA}`,
      null,
      params
    )
      .then((response) => {
        if (download) {
          if (response.url && usedIn === "GRN") {
            downloadFileFromUrl(response.url);
          } else {
            downloadInvoiceData(
              response,
              usedIn === "GRN" ? "grn_invoice" : "advance_invoice"
            );
          }
        } else {
          setBudgetList(response);
        }
      })
      .catch((err) => {});
  };

  const viewBudget = (data, type) => {
    const { approvalId = {} } = data || {};
    if (usedIn === "GRN") {
      navigate(
        `invoice-data/${data.id}?approvalId=${
          approvalId.id || ""
        }&from=${usedIn}&subIndex=6`
      );
    } else {
      navigate(`invoice-data/${data.id}?approvalId=${approvalId.id || ""}`);
    }
  };

  return (
    <div className="budget-list">
      {isUploadAllowed("paymentDetails") && status === "approved" && (
        <div className="payment-details-row">
          <DownloadTemplateButton
            type={uploadTemplates.payment_details}
            title="Payment Details Template"
          />
          <FileUpload
            buttonBackground="#fff"
            buttonColor="#008938"
            mainButtonClass="subway-finance-bulk-upload"
            borderColor="rgba(0, 138, 82, 0.5)"
            uploadText="UPLOAD PAYMENT DETAILS"
            clsName="finance-master-data-uploader"
            callBack={() => {
              loadData(filters);
            }}
            url={`${FINANCE_HOSTNAME}${
              FINANCE_REST_URLS.UPLOAD_PAYMENT_DETAILS
            }/${usedIn === "GRN" ? "grn_invoice" : "advance_invoice"}`}
            formDataKey="file"
          />
        </div>
      )}

      <div className="finance-row">
        <div className="filter-row">
          <TextField
            placeholder="Search by Invoice Document Number"
            value={searchKey}
            size="small"
            onChange={(e) => setSearchKey(e.target.value)}
          />

          <TextField
            placeholder={`Search by PO${usedIn === "GRN" ? "/GRN" : ""} Number`}
            value={filters?.poGrnNo || ""}
            size="small"
            onChange={(e) => {
              setProperty("poGrnNo", e.target.value, setFilters);
            }}
          />

          <PoFilters filters={filters} setFilters={setFilters} />

          <DateRangeFilter
            startDate={filters?.startDate}
            endDate={filters?.endDate}
            setDateRange={setFilters}
          />
          <Button
            variant="outlined"
            onClick={() => {
              loadData({ ...filters, page: 1 });
            }}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            disabled={!budgetList?.totalResults}
            onClick={() => {
              loadData(
                {
                  ...filters,
                  page: 1,
                  limit: budgetList?.totalResults,
                },
                true
              );
            }}
          >
            Download
          </Button>
        </div>
      </div>

      <MuiTable
        columnsList={getHeaderConfig(
          viewBudget,
          usedIn === "GRN" ? "grn_invoice" : "advance_invoice"
        )}
        dataList={budgetList.results || []}
        filters={filters}
        nestedValue={true}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />
    </div>
  );
};

export default InvoiceDataList;
