import { Stack, IconButton, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
export const getHeaderConfig = () => {
  const headerConfig = [
    {
      label: "Name",
      id: "name",
    },

    {
      label: "Actions",
      id: "actions",
      render: (data, onClick) => {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton sx={{ p: 0 }} onClick={() => onClick(data, "EDIT")}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onClick(data, "DELETE")} sx={{ p: 0 }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return headerConfig;
};
