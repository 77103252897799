import { getLabelsAndValuesForSelect } from "../../../../common-utilities/utils";
import { yesNoOptions } from "../../../../common-utilities/utils/constants";
import {
  validDocumentTypes,
  validStoreTypes,
} from "../../../utils/document-constants";
import { validFOFOLicenses } from "../../../utils/fofo.config";
import { showCocoAcquiredLicenseTypeBoxInForm } from "../utils/coco-acquired-utils";
import { leaseTypeDropdown } from "../utils/fofo-utils";

const isAppliedOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const fofoInputsForm = (
  formDetails,
  errors,
  storeType,
  isEditingDoc,
  docs
) => {
  if (storeType !== validStoreTypes.fofo) {
    return {};
  }
  const leaseTypes = Object.values(leaseTypeDropdown);
  const leaseSelect = getLabelsAndValuesForSelect(leaseTypes, leaseTypes);

  const fofoLicenses = Object.values(validFOFOLicenses);
  const fofoLicensesLabels = getLabelsAndValuesForSelect(
    fofoLicenses,
    fofoLicenses
  );

  let config = {
    [validDocumentTypes.franchiseAgreement]: [
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },
      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? "Required" : "",
        documentSavingKey: "document",
        uploadType: validDocumentTypes.franchiseAgreement,
        notUploadCheckBox: true,
        notUploadKey: "notApplicable",
      },
    ],
    [validDocumentTypes.subleaseAgreement]: [
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },
      {
        label: "Licensor(Landlord)*",
        name: "licensor",
        type: "text",
        value: formDetails.licensor,
        error: errors.licensor,
        helperText: errors.licensor ? "Required" : "",
      },
      {
        label: "Licensee-Firm*",
        name: "licenseeFirm",
        type: "text",
        value: formDetails.licenseeFirm,
        error: errors.licenseeFirm,
        helperText: errors.licenseeFirm ? "Required" : "",
      },
      {
        label: "Licensee-Partners*",
        name: "licenseePartners",
        type: "text",
        value: formDetails.licenseePartners,
        error: errors.licenseePartners,
        helperText: errors.licenseePartners ? "Required" : "",
      },
      {
        label: "Type of lease*",
        name: "leaseType",
        placeholder: "Select lease type",
        type: "select",
        value: formDetails.leaseType,
        error: errors.leaseType,
        options: leaseSelect,
        helperText: errors.leaseType ? "Required" : "",
      },
      {
        label: "Lease Sign Date*",
        name: "leaseSignDate",
        type: "date",
        value: formDetails.leaseSignDate,
        error: errors.leaseSignDate,
        helperText: errors.leaseSignDate ? "Required" : "",
      },
      {
        label: "Lease Effective Date*",
        name: "leaseEffectiveDate",
        type: "date",
        value: formDetails.leaseEffectiveDate,
        error: errors.leaseEffectiveDate,
        helperText: errors.leaseEffectiveDate ? "Required" : "",
      },
      {
        label: "Lease End Date*",
        name: "leaseEndDate",
        type: "date",
        value: formDetails.leaseEndDate,
        error: errors.leaseEndDate,
        helperText: errors.leaseEndDate ? "Required" : "",
      },
      {
        label: "Area in Sq. Ft.",
        name: "area",
        type: "Number",
        value: formDetails.area,
        error: errors.area,
        helperText: errors.area ? "Required" : "",
      },
      {
        label: "Current rent per month (INR.)*",
        name: "currentRent",
        type: "Number",
        value: formDetails.currentRent,
        error: errors.currentRent,
        helperText: errors.currentRent ? "Required" : "",
      },
      {
        label: "Rent Escalation / Computation formula*",
        name: "rentEscalation",
        type: "text",
        value: formDetails.rentEscalation,
        error: errors.rentEscalation,
        helperText: errors.rentEscalation ? "Required" : "",
      },
      {
        label: "Security Deposit (INR)*",
        name: "securityDeposit",
        type: "Number",
        value: formDetails.securityDeposit,
        error: errors.securityDeposit,
        helperText: errors.securityDeposit ? "Required" : "",
      },
      {
        label: "Location Address*",
        name: "locationAddress",
        type: "text",
        value: formDetails.locationAddress,
        error: errors.locationAddress,
        helperText: errors.locationAddress ? "Required" : "",
      },
      {
        label: "Lock in period in Months",
        name: "lockInPeriod",
        type: "Number",
        value: formDetails.lockInPeriod,
        error: errors.lockInPeriod,
        helperText: errors.lockInPeriod ? "Required" : "",
      },
      {
        label: "Lock in expiry date",
        name: "lockInExpiryDate",
        type: "date",
        value: formDetails.lockInExpiryDate,
        error: errors.lockInExpiryDate,
        helperText: errors.lockInExpiryDate ? "Required" : "",
      },
      {
        label: "Renewal clause*",
        name: "renewalClause",
        type: "text",
        value: formDetails.renewalClause,
        error: errors.renewalClause,
        helperText: errors.renewalClause ? "Required" : "",
      },
      {
        label: "Stamped & Registered*",
        name: "stampedAndRegistered",
        placeholder: "Stamped & Registered",
        type: "select",
        value: formDetails.stampedAndRegistered,
        error: errors.stampedAndRegistered,
        options: yesNoOptions,
        helperText: errors.stampedAndRegistered ? "Required" : "",
      },
      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? "Required" : "",
        documentSavingKey: "document",
        uploadType: validDocumentTypes.subleaseAgreement,
        notUploadCheckBox: true,
        notUploadKey: "notApplicable",
      },
    ],
    [validDocumentTypes.operatingAssignment]: [
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },

      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.docs,
        error: errors.docs,
        helperText: errors.docs ? "Required" : "",
        documentSavingKey: "document",
        uploadType: validDocumentTypes.operatingAssignment,
        notUploadCheckBox: true,
        notUploadKey: "notApplicable",
      },
    ],
    [validDocumentTypes.license]: [
      {
        label: "License Type*",
        name: "licenseType",
        type: "select",
        placeholder: "Select license type",
        value: formDetails.licenseType,
        error: errors.licenseType,
        options: fofoLicensesLabels,
        helperText: errors.licenseType ? "Required" : "",
      },
      {
        label: "Start Date*",
        name: "startDate",
        type: "date",
        value: formDetails.startDate,
        error: errors.startDate,
        helperText: errors.startDate ? "Required" : "",
      },
      {
        label: "End Date*",
        name: "endDate",
        type: "date",
        value: formDetails.endDate,
        error: errors.endDate,
        helperText: errors.endDate ? "Required" : "",
      },
      {
        label: "Is Applied",
        name: "isApplied",
        type: "select",
        placeholder: "Is Applied",
        value: formDetails.isApplied,
        error: errors.isApplied,
        options: isAppliedOptions,
        disabled: isEditingDoc && !!docs?.length,
      },
      {
        label: "Upload Document",
        name: "docs",
        type: "upload",
        value: formDetails.licenseUpload,
        error: errors.licenseUpload,
        helperText: errors.licenseUpload ? "Required" : "",
        documentSavingKey: "document",
        uploadType: "licenceType",
        notUploadCheckBox: showCocoAcquiredLicenseTypeBoxInForm(
          formDetails,
          storeType
        ),
        notUploadKey: "notApplicable",
      },
    ],
  };
  return config;
};
