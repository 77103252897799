import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { BD_LEAD_HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/bd-lead";

import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { mainDataTableConfig } from "./config";

import "./index.scss";
import { leadStatuses, userRoles } from "../../utils/constants";

import { InfomationModal } from "../../core/custom-modals/information.modal";
import SearchIcon from "@mui/icons-material/Search";
import { actionIconHandler } from "./utils";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import AutoCompleteMultiSelect from "../../../common-utilities/core/autocomplete-multiselect";

import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import { fmtDwndRpt } from "./utils/report-format.utils";
import { getFieldList } from "./utils/apis";
import { setProperty } from "../../../common-utilities/utils/misc";
import { CreateUpdateStoreModal } from "../../../store-master/components/store-master/create-update-modal";

const BdLeadDocument = ({ tokenKey }) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [filterLists, setFilterLists] = useState({ catchment: [] });
  const user = getOfflineData(
    tokenKey ? tokenKey : tokenMainKeys.bdLead,
    "user"
  );
  const [modalDetails, setModalDetails] = useState({
    data: null,
    title: "",
    useSiTable: false,
    labelMap: null,
    type: null,
  });

  const closeModal = () => {
    setModalDetails({
      data: null,
      title: "",
      useSiTable: false,
      labelMap: null,
      type: null,
    });
  };

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
    users: [],
    statuses: [],
  });

  const [
    filtersAfterClickingOnApplyButton,
    setFiltersAfterClickingOnApplyButton,
  ] = useState({
    users: [],
    statuses: [],
  });

  const getAllUsers = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.USERS_LIST}`,
      null,
      null,
      null,
      tokenKey
    )
      .then((res) => {
        if (res?.message) {
          return;
        }
        setUsersList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadData = (filter, usePrevFilters, downloadReport) => {
    const filterParams =
      typeof filter === "object" ? { ...filter } : { ...filters };
    if (!filters.limit) {
      filterParams.limit = filters.limit;
    }
    if (!filters.page) {
      filterParams.page = filters.page;
    }

    if (!filters.sortBy) {
      filterParams.sortBy = filters.sortBy;
    }

    if (!filterParams.users || filterParams.users.length === 0) {
      delete filterParams.users;
    }
    if (!filterParams.statuses || filterParams.statuses.length === 0) {
      delete filterParams.statuses;
    }
    if (filters?.users?.length > 0) {
      filterParams.users = JSON.stringify(
        filters.users.map((item) => item._id)
      );
    }

    if (filters?.catchmentList?.length) {
      filterParams.catchmentList = JSON.stringify(filterParams.catchmentList);
    } else {
      delete filterParams.catchmentList;
    }

    if (filters?.statuses?.length) {
      filterParams.statuses = JSON.stringify(filterParams.statuses);
    }

    if (usePrevFilters) {
      if (filtersAfterClickingOnApplyButton.users.length > 0) {
        filterParams.users = JSON.stringify(
          filtersAfterClickingOnApplyButton.users.map((item) => item.id)
        );
      } else {
        delete filterParams.users;
      }

      if (filtersAfterClickingOnApplyButton?.statuses?.length) {
        filterParams.statuses = JSON.stringify(
          filtersAfterClickingOnApplyButton.statuses
        );
      } else {
        delete filterParams.statuses;
      }
      if (filtersAfterClickingOnApplyButton.catchmentList?.length) {
        filterParams.catchmentList = JSON.stringify(
          filtersAfterClickingOnApplyButton.catchmentList
        );
      } else {
        delete filterParams.catchmentList;
      }
    }

    if (downloadReport) {
      filterParams.page = 1;
      filterParams.limit = tableData.totalResults;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${BD_LEAD_HOSTNAME}${REST_URLS.LEADS_LIST}`,
      null,
      {
        ...filterParams,
      },
      null,
      tokenKey
    )
      .then((res) => {
        if (res?.message) {
          return;
        }
        if (downloadReport) {
          fmtDwndRpt(res);
          return;
        }
        setTableData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
    loadData(filters);
  }, []);

  useEffect(() => {
    getFieldList("locationInfo.catchment", "", tokenKey, (list) => {
      setProperty("catchment", list, setFilterLists);
    });
  }, [tokenKey]);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  const createLeadHandler = () => {
    navigate("/bd-lead/lead");
  };

  return (
    <div className="leads-table-container">
      <div
        className={`${
          user?.role === userRoles.admin
            ? "create-new-container-admin"
            : "create-new-container-user"
        }`}
      >
        <div className="user-filters">
          {user?.role === userRoles.admin && (
            <div className="autocomplete-container">
              <AutoCompleteMultiSelect
                className="bd-lead"
                value={filters.users}
                onChange={(_, value) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    users: value,
                  }));
                }}
                options={usersList}
                placeholder="Select Users"
                labelKey="name"
                valueKey="_id"
              />

              <SearchIcon
                onClick={() => {
                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  loadData({ ...filters, page: 1 });
                  setFiltersAfterClickingOnApplyButton((prevFilters) => ({
                    ...prevFilters,
                    users: filters.users,
                    statuses: filters.statuses,
                  }));
                }}
                className="search-icon"
              />
            </div>
          )}

          <div className="autocomplete-container">
            <AutoCompleteMultiSelect
              className="bd-lead"
              value={filters.statuses}
              onChange={(_, value) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  statuses: value,
                }));
              }}
              options={leadStatuses}
              placeholder="Select Status"
              isoptVals={true}
            />

            <SearchIcon
              onClick={() => {
                setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                loadData({ ...filters, page: 1 });
                setFiltersAfterClickingOnApplyButton((prevFilters) => ({
                  ...prevFilters,
                  users: filters.users,
                  statuses: filters.statuses,
                }));
              }}
              className="search-icon"
            />
          </div>
          <div className="autocomplete-container">
            <AutoCompleteMultiSelect
              className="bd-lead"
              value={filters.catchmentList || []}
              onChange={(_, value) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  catchmentList: value,
                }));
              }}
              options={filterLists.catchment}
              placeholder="Select Catchments"
              isoptVals={true}
            />

            <SearchIcon
              onClick={() => {
                setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                loadData({ ...filters, page: 1 });
                setFiltersAfterClickingOnApplyButton((prevFilters) => ({
                  ...prevFilters,
                  users: filters.users,
                  catchmentList: filters.catchmentList,
                }));
              }}
              className="search-icon"
            />
          </div>
        </div>

        <div className="create-button-container">
          {tableData.totalResults && (
            <DownloadBtn
              onClick={() => {
                loadData(filters, true, true);
              }}
            />
          )}
          {!tokenKey && user.role !== "READ_ONLY" && (
            <CommonBtn
              size="small"
              variant="contained"
              onClick={createLeadHandler}
              text="Create New"
            />
          )}
        </div>
      </div>
      <MuiTable
        columnsList={mainDataTableConfig(tokenKey)}
        dataList={tableData.results || []}
        filters={filters}
        pageCount={tableData.totalPages}
        onClick={(data, type) => {
          actionIconHandler(data, type, navigate, setModalDetails);
        }}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData(
            {
              ...filters,
              page,
            },
            true
          );
        }}
      ></MuiTable>

      {modalDetails.data && (
        <InfomationModal
          title={modalDetails.title}
          onClose={closeModal}
          useSiTable={modalDetails.useSiTable}
          data={modalDetails.data}
          allLabelsAndKeys={modalDetails.labelMap}
          onSuccess={() => {
            loadData();
            closeModal();
          }}
        />
      )}

      <CreateUpdateStoreModal
        showForm={modalDetails?.type === "create-lead-store"}
        createStoreData={modalDetails?.storeData}
        setShowForm={closeModal}
        url={modalDetails?.url}
        onSuccess={() => {
          loadData();
          closeModal();
        }}
        tokenKey={tokenMainKeys.bdLead}
      />
    </div>
  );
};

export default BdLeadDocument;
