import { qnetEndPoint } from "./endpoints";
import { getOfflineData } from "./offline-services";
export {
  getValue,
  convertToCamelCaseFromUnderScore,
  getStartDate,
  getEndDate,
  exportToExcel,
  getLabelsAndValuesForSelect,
  dateFun,
} from "../../common-utilities/utils";
export const userTypes = {
  STORE_OWNER: "STORE_OWNER",
  ADMIN: "ADMIN",
  TECH_NET_USER: "TECH_NET_USER",
};

export const CASE_MAP_FILTER = {
  PENDING_SUPPLIER_ASSIGNED: ["ASSIGNED_TO_DC", "ASSIGNED_TO_VENDOR"],
  ASSIGNED_TO_DC: [
    "FAULT_ACCEPTED_BY_DC",
    "FAULT_REJECTED_BY_DC",
    "IN_MEDIATION_BY_DC",
    "PENDING_RESEARCH_BY_DC",
  ],
  ASSIGNED_TO_VENDOR: [
    "FAULT_REJECTED_BY_VENDOR",
    "IN_MEDIATION_BY_VENDOR",
    "FAULT_ACCEPTED_BY_VENDOR",
    "PENDING_RESEARCH_BY_VENDOR",
  ],
  FAULT_ACCEPTED_BY_DC: ["ADOPTED_BY_DC"],
  FAULT_ACCEPTED_BY_VENDOR: ["ADOPTED_BY_VENDOR"],
  ADOPTED_BY_DC: ["PENDING_CREDIT"],
  ADOPTED_BY_VENDOR: ["PENDING_CREDIT"],
  PENDING_CREDIT: ["CREDIT_ISSUED"],
  CREDIT_ISSUED: ["REVIEW_BY_ADMIN"],
  IN_MEDIATION_BY_DC: ["ASSIGNED_TO_DC", "ASSIGNED_TO_VENDOR"],
  IN_MEDIATION_BY_VENDOR: ["ASSIGNED_TO_DC", "ASSIGNED_TO_VENDOR"],
  PENDING_RESEARCH_BY_VENDOR: [
    "ASSIGNED_TO_VENDOR",
    "FAULT_ACCEPTED_BY_VENDOR",
    "PENDING_RESEARCH_BY_VENDOR",
  ],
  PENDING_RESEARCH_BY_DC: [
    "ASSIGNED_TO_DC",
    "FAULT_ACCEPTED_BY_DC",
    "FAULT_REJECTED_BY_DC",
  ],
  REVIEW_BY_ADMIN: ["CREDIT_CONFIRMATION"],
  CREDIT_CONFIRMATION: [],
};

export const getUserRole = () => {
  const user = getOfflineData(null, "user");
  if (user && user.role) {
    return user.role || {};
  }
};

export const redirection = (navigate) => {
  const roleDetails = getUserRole();
  if (!roleDetails) {
    navigate("/");
    return;
  }
  if (
    roleDetails.type !== USER_ROLES.ADMIN &&
    roleDetails.type !== USER_ROLES.RD
  ) {
    navigate(`${qnetEndPoint}/cases`);
  }
};

export const USER_ROLES = {
  ADMIN: "ADMIN",
  STORE_OWNER: "STORE_OWNER",
  QA: "QA",
  DC: "DC",
  VENDOR: "VENDOR",
  RD: "R&D",
  STORE: "STORE",
  ACTION_TAKER: "ACTION_TAKER",
  CREDIT_ISSUER: "CREDIT_ISSUER",
  AM: "AM",
  USER: "USER",
};

export const IMAGE_TYPES = ["jpeg", "jpg", "png"];

export const belongsToInUserRoles = {
  dc: "DC",
  vendor: "VENDOR",
  subway: "SUBWAY",
};

export const allCasesStatues = {
  mediation: "MEDIATION",
  adminReview: "REVIEW_BY_ADMIN",
  creditIssed: "CREDIT_ISSUED",
};

export const unitOfMeasure = [
  { label: "Each", value: "Each" },
  { label: "Case", value: "Case" },
  { label: "Kg", value: "Kg" },
  { label: "Liter", value: "Liter" },
];

export const closeModal = (setModalDetailsFunction) => {
  if (setModalDetailsFunction) {
    setModalDetailsFunction({
      isOpen: false,
      title: "",
      data: null,
    });
  }
};
