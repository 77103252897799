export const caseStatuses = [
  {
    ul: "Pending Supplier Assigned",
    bl: "PENDING_SUPPLIER_ASSIGNED",
  },
  {
    ul: "Assigned To Dc",
    bl: "ASSIGNED_TO_DC",
  },
  {
    ul: "Assigned To Vendor",
    bl: "ASSIGNED_TO_VENDOR",
  },
  {
    ul: "Fault Accepted",
    bl: "FAULT_ACCEPTED",
  },
  // {
  //   ul: "Fault Rejected",
  //   bl: "FAULT_REJECTED",
  // },
  {
    ul: "Mediation",
    bl: "MEDIATION",
  },

  {
    ul: "Pending Credit",
    bl: "PENDING_CREDIT",
  },
  {
    ul: "Credit Issued",
    bl: "CREDIT_ISSUED",
  },
  {
    ul: "Review By Admin",
    bl: "REVIEW_BY_ADMIN",
  },
  {
    ul: "Credit Confirmation by store",
    bl: "CREDIT_CONFIRMATION",
  },
  {
    ul: "Closed",
    bl: "CLOSED",
  },
  {
    ul: "Closure By Admin",
    bl: "CLOSURE_BY_ADMIN",
  },
];

export const storeTypes = {
  ALL: "ALL",
  COCO_BUILT: "COCO_BUILT",
  COCO_ACQUIRED: "COCO_ACQUIRED",
  FOFO: "FOFO",
};

export const storeTypeLabels = [
  {
    ul: "All",
    bl: storeTypes.ALL,
  },
  {
    ul: "Coco Built",
    bl: storeTypes.COCO_BUILT,
  },
  {
    ul: "Coco Acquired",
    bl: storeTypes.COCO_ACQUIRED,
  },
  {
    ul: "FOFO",
    bl: storeTypes.FOFO,
  },
];

export const monthLabels = [
  { ul: "January", bl: 1 },
  { ul: "February", bl: 2 },
  { ul: "March", bl: 3 },
  { ul: "April", bl: 4 },
  { ul: "May", bl: 5 },
  { ul: "June", bl: 6 },
  { ul: "July", bl: 7 },
  { ul: "August", bl: 8 },
  { ul: "September", bl: 9 },
  { ul: "October", bl: 10 },
  { ul: "November", bl: 11 },
  { ul: "December", bl: 12 },
];

export const getAllYearLabels = (ul = "ul", bl = "bl") => {
  const yearLabels = [];
  let start = 2023;
  const year = new Date().getFullYear();
  while (start <= year) {
    yearLabels.push({
      [ul]: `${start}`,
      [bl]: start,
    });
    start += 1;
  }
  return yearLabels;
};

export const sortDateLabels = [
  { label: "Ascending Order", value: 1 },
  { label: "Descending Order", value: -1 },
];

export const DateRangePickerLabels = [
  {
    label: "Last 7 days",
    value: [
      new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
      new Date(),
    ],
  },
  {
    label: "Last 30 days",
    value: [
      new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000),
      new Date(),
    ],
  },
];

export const complaintCategoryTypeOptions = [
  { label: "Product", value: "Product" },
  { label: "Service", value: "Service" },
];

export const complaintTypeOwners = [
  { label: "Supplier", value: "Supplier" },
  { label: "Distributor", value: "Distributor" },
];
