import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Autocomplete,
  TextField,
  Tooltip,
  Box,
} from "@mui/material";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const BreadList = ({
  data,
  selectedDate,
  selectedCycle,
  setSelectedCycle,
  setSelectedDate,
}) => {
  const [cycle, setCycle] = useState("");
  const [date, setDate] = useState("");
  const currentHour = new Date().getHours();
  const isCycle1 = currentHour >= 17 || currentHour < 7;

  useEffect(() => {
    let cycleVal = isCycle1 ? "Cycle 1, 7AM to 5PM)" : "Cycle 2, 5PM to 3AM";
    let dateVal = isCycle1
      ? moment().add(1, "day").format("ll")
      : moment().format("ll");
    if (selectedCycle && selectedDate) {
      dateVal =
        selectedDate === "Tomorrow"
          ? moment().add(1, "day").format("ll")
          : moment().format("ll");
      cycleVal =
        selectedCycle === "Cycle 2"
          ? "Cycle 2, 5PM to 3AM"
          : "Cycle 1, 7AM to 5PM)";
    }
    setCycle(cycleVal);
    setDate(dateVal);
  }, [selectedCycle, selectedDate]);
  return (
    <div>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {`Bread Prediction for ${date} (${cycle})`}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}
      >
        <Grid item xs={4}>
          <Autocomplete
            options={["Today", "Tomorrow"]}
            value={selectedDate}
            onChange={(event, newValue) => setSelectedDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Date" size="small" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={["Cycle 1", "Cycle 2"]}
            value={selectedCycle}
            onChange={(event, newValue) => setSelectedCycle(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Cycle" size="small" />
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ justifyContent: "flex-end", display: "flex", mb: 1 }}>
        <Tooltip
          arrow
          title="Bread Prediction Calculation - Average bread consumption for the same day across 6 weeks and increase by 10 % for the result."
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: "14px",
                maxWidth: "300px",
              },
            },
          }}
        >
          <InfoOutlinedIcon color="success" />
        </Tooltip>
      </Box>

      {data.length > 0 ? (
        <Box>
          <TableContainer sx={{ maxHeight: 440, background: "#fcf6f68c" }}>
            <Table size="small">
              <TableHead sx={{ background: "#008938" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Type Of Bread</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Average Quantity (Qty in Footlong)
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Predicted Quantity (Qty in Footlong)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((date, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{date.Type_of_Bread}</TableCell>
                      <TableCell align="center">{date.Avg_Quantity}</TableCell>
                      <TableCell align="center">
                        {date.Predicted_Bread}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography sx={{ color: "red", mt: 1 }}>
            Adjust for big days manually, if they are present in past 6 weeks
          </Typography>
        </Box>
      ) : (
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            color: "gray",
            mt: 2,
          }}
        >
          No Data Found
        </Typography>
      )}
    </div>
  );
};

export default BreadList;
