import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { Card, CardContent, Typography } from "@mui/material";

const AhtReport = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
      ],
    },
    yaxis: {
      title: {
        text: "Days",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Days`,
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Previous Year",
      data: [],
    },
    {
      name: "Current Year",
      data: [],
    },
  ]);

  const getFinancialYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentFY = currentMonth >= 4 ? currentYear : currentYear - 1;
    const previousFY = currentFY - 1;

    return {
      currentFY: `${currentFY}-${currentFY + 1}`,
      previousFY: `${previousFY}-${previousFY + 1}`,
    };
  };

  const transformData = (data, years) => {
    const reorderedMonths = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

    const previousYearData = reorderedMonths.map(
      (month) => data[month]?.[years.previousFY] || 0
    );
    const currentYearData = reorderedMonths.map(
      (month) => data[month]?.[years.currentFY] || 0
    );

    return [previousYearData, currentYearData];
  };
  const getAhtData = () => {
    const years = getFinancialYears();
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.AHT_REPORT}`)
      .then((res) => {
        if (!res.message) {
          const [previousYearData, currentYearData] = transformData(res, years);

          setChartSeries([
            { name: `FY-${years.previousFY}`, data: previousYearData },
            { name: `FY-${years.currentFY}`, data: currentYearData },
          ]);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAhtData();
  }, []);

  return (
    <div>
      <Card sx={{ width: "90%", margin: "auto" }}>
        <CardContent>
          <Typography variant="h6" textAlign="center">
            AHT In Days
          </Typography>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={500}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default AhtReport;
