/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import { getHeaderConfig } from "./config";
import { defaultFltrDates, lastMonthDate } from "../../utils";
import { invokeApi, HTTP_METHODS, plainApi } from "../../utils/http-service";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DownloadModal, ApproverDetailsModal } from "../customerModals";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  allStores,
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from "../../utils/constants";
import { dateFun, exportToExcel } from "../../../common-utilities/utils";
import { DeleteModal } from "./modal";
import { processExcelReport } from "./utils/download-reports";
import MuiTable from "../../../common-utilities/core/mui-table";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import CommonBtn from "../../../common-utilities/core/common-button";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import SearchIcon from "@mui/icons-material/Search";
import { lakhConversion } from "../../../common-utilities/utils/misc2";
import { addCorrectStartEndDate } from "../../../common-utilities/utils/time";
import FieldAutoComplete from "../document/auto-complete";

const CfApprovalReports = ({ tokenKey }) => {
  const [tableItems, setTableItems] = useState(null);
  const [approverData, setApproverData] = useState(null);
  const [allApproved, setAllApproved] = useState(false);
  const [reports, setReports] = useState([]);
  const [storeType, setStoreType] = useState(null);
  const [deleteModalDetails, setDeleteModalDetails] = useState(null);
  const [storeOptions, setStoreOptions] = useState([]);
  const [searchResId, setSearchResId] = useState("");

  const defaultDates = defaultFltrDates();

  const [filtersAfterSearch, setFiltersAfterSearch] = useState({
    allApproved: false,
    startDate: defaultDates[0],
    endDate: defaultDates[1],
  });
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const initialFilters = {
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  };

  const [dateRange, setDateRange] = useState({
    startDate: defaultDates[0],
    endDate: defaultDates[1],
  });
  const dateRangeHandler = (e) => {
    setDateRange((prevVal) => ({
      ...prevVal,
      startDate: new Date(`${e[0]}`).toISOString(),
      endDate: new Date(`${e[1]}`).toISOString(),
    }));
  };
  const storeList = allStores.map((item) => {
    return { label: item, value: item };
  });

  const loadData = (
    loadfilter,
    downloadReports,
    getDataOnTheBasisOfValueSetAfterSearch
  ) => {
    const filter =
      loadfilter && typeof loadfilter === "object"
        ? { ...loadfilter }
        : { ...filters };
    let allfilter = {};
    filter.storeType = storeType?.value;

    if (!filter.page) {
      filter.page = filters.page;
    }

    if (!filter.sortBy) {
      filter.sortBy = filters.sortBy;
    }

    if (!filter.limit) {
      filter.limit = filters.limit;
    }

    if (downloadReports) {
      filter.page = 1;
      filter.limit = reports.totalResults;
    }

    if (getDataOnTheBasisOfValueSetAfterSearch) {
      if (filtersAfterSearch.startDate) {
        filter.startDate = filtersAfterSearch.startDate;
      }
      if (filtersAfterSearch.endDate) {
        filter.endDate = filtersAfterSearch.endDate;
      }
      if (filtersAfterSearch.allApproved) {
        allfilter = { ...allfilter, showOnlyApproved: true };
      }
    } else {
      if (dateRange.startDate) {
        filter.startDate = dateRange.startDate;
      }

      if (dateRange.endDate) {
        filter.endDate = dateRange.endDate;
      }
      if (allApproved) {
        allfilter = { ...allfilter, showOnlyApproved: true };
      }
    }

    const finalFilter = {
      ...filter,
      ...allfilter,
    };
    addCorrectStartEndDate(finalFilter);

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.REPORTS}`,
      null,
      finalFilter,
      null,
      tokenKey
    )
      .then((response) => {
        if (response) {
          if (downloadReports) {
            const results = response.results.map((item) => {
              const { approvedDates } = item;
              const businessDetails = item?.businessDetails || {};
              const isCocoAcquired = item?.Type === storeTypes.cocoAcquired;
              const keys = isCocoAcquired
                ? { ...cocoAcqKeyNames }
                : { ...cocoBuiltKeyNames };
              let finalItem = {};
              if (isCocoAcquired) {
                finalItem["Restaurant Id"] = item?.Store;
              }

              finalItem = {
                "Approval Id": item?.id,
                ...finalItem,
                "Restaurant Name": item?.["Restaurant Name"],
                "Creation Date": item?.["Created At"],
                Type: item?.Type,
                "Price Cohort": item?.["Price Cohort"],
                "Ordering Price": item?.["Ordering Price"]?.join(", "),
              };
              if (isCocoAcquired) {
                finalItem = {
                  ...finalItem,
                  "Approver 1": item?.["Approver1 Name"],
                  "Approver 1 Status": item?.["Approver 1"] || "Pending",
                  "Approver 1 Date": approvedDates?.["Approver1 Date"],
                  "Approver 1 Comment": item?.["Approver1 Comment"],
                  "Approver 2": item?.["Approver2 Name"],
                  "Approver 2 Status": item?.["Approver 2"] || "Pending",
                  "Approver 2 Date": approvedDates?.["Approver2 Date"],
                  "Approver 2 Comment": item?.["Approver2 Comment"],
                  "Approver 3": item?.["Approver3 Name"],
                  "Approver 3 Status": item?.["Approver 3"] || "Pending",
                  "Approver 3 Date": approvedDates?.["Approver3 Date"],
                  "Approver 3 Comment": item?.["Approver3 Comment"],
                  "Approver 4": item?.["Approver4 Name"],
                  "Approver 4 Status": item?.["Approver 4"] || "Pending",
                  "Approver 4 Date": approvedDates?.["Approver4 Date"],
                  "Approver 4 Comment": item?.["Approver4 Comment"],
                  "Approver 5": item?.["Approver5 Name"],
                  "Approver 5 Status": item?.["Approver 5"] || "Pending",
                  "Approver 5 Date": approvedDates?.["Approver5 Date"],
                  "Approver 5 Comment": item?.["Approver5 Comment"],
                  "Approver 6": item?.["Approver6 Name"],
                  "Approver 6 Status": item?.["Approver 6"] || "Pending",
                  "Approver 6 Date": approvedDates?.["Approver6 Date"],
                  "Approver 6 Comment": item?.["Approver6 Comment"],
                };
              } else {
                finalItem = {
                  ...finalItem,
                  "Approver 1": item?.["Approver1 Name"],
                  "Approver 1 Status": item?.["Approver 1"] || "Pending",
                  "Approver 1 Date": approvedDates?.["Approver1 Date"],
                  "Approver 1 Comment": item?.["Approver1 Comment"],
                  "Approver 2": item?.["Approver2 Name"],
                  "Approver 2 Status": item?.["Approver 2"] || "Pending",
                  "Approver 2 Date": approvedDates?.["Approver2 Date"],
                  "Approver 2 Comment": item?.["Approver2 Comment"],
                  "Approver 3": item?.["Approver3 Name"],
                  "Approver 3 Status": item?.["Approver 3"] || "Pending",
                  "Approver 3 Date": approvedDates?.["Approver3 Date"],
                  "Approver 3 Comment": item?.["Approver3 Comment"],
                  "Approver 4": item?.["Approver4 Name"],
                  "Approver 4 Status": item?.["Approver 4"] || "Pending",
                  "Approver 4 Date": approvedDates?.["Approver4 Date"],
                  "Approver 4 Comment": item?.["Approver4 Comment"],
                  "Approver 5": item?.["Approver5 Name"],
                  "Approver 5 Status": item?.["Approver 5"] || "Pending",
                  "Approver 5 Date": approvedDates?.["Approver5 Date"],
                  "Approver 5 Comment": item?.["Approver5 Comment"],
                  "Approver 6": item?.["Approver6 Name"],
                  "Approver 6 Status": item?.["Approver 6"] || "Pending",
                  "Approver 6 Date": approvedDates?.["Approver6 Date"],
                  "Approver 6 Comment": item?.["Approver6 Comment"],
                };
              }

              finalItem = {
                ...finalItem,
                "Rejected By": item?.["Rejected By"],
                "Rejected At": item?.["Rejected At"] || null,
              };
              const capexKey = isCocoAcquired
                ? "Total Acquistion Cost (INR lacs)"
                : "Total Capex (INR lacs)";

              finalItem = {
                ...finalItem,
                [keys.ads]: item?.["ADS (Avg Daily Sales)"],
                [keys.deliveryPercentage]: item?.["Delivery %"],
                [keys.occupancyPercentage]: item?.["Occupancy %"],
                [keys.labourPercentage]: item?.["Labour %"],
                [keys.ebitdaPercentage]: item?.["EBITDA %"],
                [keys.totalCapex]: item?.[capexKey],
                "Payback (in months)": item?.["Payback (in months)"],
              };
              if (isCocoAcquired) {
                finalItem = {
                  ...finalItem,
                  [keys.adsTtm]: businessDetails?.adsTtm,
                  [keys.ads]: item?.["ADS (Avg Daily Sales)"],
                  [keys.ttmEbitdaPercentage]:
                    businessDetails?.ttmEbitdaPercentage,
                  [keys.ebitdaPercentage]: item?.["EBITDA %"],
                  [keys.ttmAdjustedEbitdaMultiple]:
                    businessDetails?.ttmAdjustedEbitdaMultiple,
                  [keys.forward1YearEbitdaMultiple]:
                    businessDetails?.forward1YearEbitdaMultiple,

                  [keys.deliveryPercentage]: item?.["Delivery %"],
                  [keys.occupancyPercentage]: item?.["Occupancy %"],
                  [keys.labourPercentage]: item?.["Labour %"],
                  [keys.totalCapex]: item?.[capexKey],
                  "Payback (in months)": item?.["Payback (in months)"],
                  [keys.grossOfferExtendedA]: lakhConversion(
                    businessDetails?.grossOfferExtendedA,
                    false
                  ),
                };
              }

              return finalItem;
            });
            exportToExcel(
              results,
              "Reports",
              null,
              null,
              (workbook, worksheet, sheetName, headers) => {
                processExcelReport(
                  workbook,
                  worksheet,
                  sheetName,
                  headers,
                  results,
                  filter.storeType
                );
              }
            );
            return;
          }
          setReports(response);
        }
      })
      .catch((err) => {
        toast.error("Failed to load Reports");
      });
  };

  useEffect(() => {
    if (storeType) {
      setFilters(initialFilters);
      loadData(initialFilters);
    }
  }, [storeType]);

  const downloadReport = async () => {
    loadData(filters, true, true);
  };

  const onClickShowDataFunction = (data, type, approverNo) => {
    if (data) {
      switch (type) {
        case "Approver":
          setApproverData({
            title: `Approver ${approverNo} Details`,
            data: [
              ["Name", data?.[`Approver${approverNo} Name`]],
              ["Comment", data?.[`Approver${approverNo} Comment`]],
              ["Status", data?.[`Approver ${approverNo}`]],
              [
                "Approval Date",
                dateFun(
                  data?.approvedDates?.[`Approver${approverNo} Date`],
                  true
                ),
              ],
            ],
          });
          break;
        case "delete approval":
          setDeleteModalDetails({
            data,
          });
          break;
        default:
          const isCocoAcquired = data?.Type === storeTypes.cocoAcquired;
          const oddLabel = isCocoAcquired ? "ODD" : "Site approval pack";

          let finalData = [
            { label: "Unit Economics Model", value: "" },
            { label: oddLabel, value: "" },
          ];
          const { uploadedDocuments, snapShot } = data;
          if (uploadedDocuments) {
            const {
              fiveyearplan,
              newStoreDetailsPPT,
              newStoreDetailsExcel,
              approvalEmail,
              geoIqSiteReport,
            } = uploadedDocuments;
            finalData[0].value = fiveyearplan || "";
            finalData[1].value = newStoreDetailsPPT || "";
            if (!isCocoAcquired) {
              finalData.push({
                label: "New Store Details (excel)",
                value: newStoreDetailsExcel,
              });
              finalData.push({
                label: "GeoIQ site report",
                value: geoIqSiteReport,
              });
            }
            if (isCocoAcquired && approvalEmail) {
              finalData.push({
                label: "Approval Email",
                value: approvalEmail,
              });
            }
          }
          if (snapShot) {
            const {
              competitionAndNearbyRestaurantSales,
              gapmapSnapshot,
              unitEconomicModel,
            } = snapShot;
            finalData = [
              ...finalData,
              {
                label: `${
                  isCocoAcquired
                    ? cocoAcqKeyNames.competitionAndNearbyRestaurantSales
                    : cocoBuiltKeyNames.competitionAndNearbyRestaurantSales
                }`,
                value: competitionAndNearbyRestaurantSales,
              },
              {
                label: "Unit Economics Model UEM Cover",
                value: unitEconomicModel,
              },
              {
                label: `${
                  isCocoAcquired ? "Total Acquisition Cost" : "Gapmap snapshot"
                }`,
                value: gapmapSnapshot,
              },
            ];
          }
          setTableItems(finalData);
          break;
      }
    }
  };
  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
        null,
        {
          storeNumber: searchTerm,
        }
      ).then((res) => {
        if (res?.results) {
          const storeList = res?.results?.map((ele) => ele?.storeNumber);
          setStoreOptions(storeList);
        }
      });
    }, 300);
  };
  const inputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
      setSearchResId(value);
    }
    if (!value) {
      setSearchResId("");
      setStoreOptions([]);
    }
  };
  return (
    <>
      <div className="report-container">
        <div className="mainReportHeader">
          <div className="reportsdateAndSelectContainer">
            <DateRangePicker
              cleanable={false}
              placeholder="Select Date Range"
              onChange={dateRangeHandler}
              value={[
                dateRange.startDate ? new Date(dateRange.startDate) : null,
                dateRange.endDate ? new Date(dateRange.endDate) : null,
              ]}
            />

            <AutocompleteComponent
              label="Search Restaurant Id"
              onInputChange={inputChange}
              noValue={true}
              options={storeOptions}
            />

            <FieldAutoComplete
              fieldName="restaurantName"
              label="Select Restaurant Name"
              setDataFunc={setFilters}
              keyName="restaurantName"
              filter={{ storeType: filters.storeType }}
            />
            <FormControlLabel
              value="start"
              checked={allApproved}
              className="approvalLabel"
              control={
                <Checkbox
                  color="primary"
                  onChange={() => {
                    setAllApproved((prevVal) => !prevVal);
                  }}
                />
              }
              label="All Approved"
              labelPlacement="start"
            />
            <div className="store-type">
              <AutocompleteComponent
                label="Select Type"
                options={storeList}
                onChange={(e, value) => {
                  if (value) {
                    setStoreType(null);
                    setTimeout(() => {
                      setStoreType(value);
                    }, 250);
                    return;
                  }
                  setStoreType(value);
                }}
                fullWidth={true}
                defaultValue={storeType?.label || ""}
                value={storeType || null}
              />
            </div>

            <div>
              <SearchIcon
                onClick={() => {
                  if (!storeType) {
                    toast.error("Please select type", { autoClose: 2000 });
                    return;
                  }
                  setFiltersAfterSearch({
                    allApproved,
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                  });
                  setFilters((prevFilters) => ({ ...prevFilters, page: 1 }));
                  if (searchResId) {
                    loadData({
                      ...filters,
                      page: 1,
                      restaurantId: searchResId,
                    });
                  } else {
                    loadData({ ...filters, page: 1 });
                  }
                }}
                sx={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="downloadReportButton">
            {(reports.results || []).length > 0 && (
              <DownloadBtn
                onClick={() => {
                  downloadReport();
                }}
              />
            )}
          </div>
        </div>
        <div>
          {storeType && (
            <MuiTable
              columnsList={getHeaderConfig(storeType?.value)}
              dataList={reports.results || []}
              filters={filters}
              pageCount={reports.totalPages || 1}
              onClick={onClickShowDataFunction}
              nestedValue={true}
              onChange={(page) => {
                setFilters({
                  ...filters,
                  page,
                });
                loadData({ limit: filters.limit, page }, null, true);
              }}
            ></MuiTable>
          )}
        </div>
        {approverData && (
          <ApproverDetailsModal
            title={approverData?.title || ""}
            closeModal={setApproverData}
            data={approverData?.data || []}
          />
        )}
        {tableItems && (
          <DownloadModal
            title="Uploaded files"
            closeModal={setTableItems}
            data={tableItems}
            tableClassName
          />
        )}

        <DeleteModal
          showModal={deleteModalDetails}
          data={deleteModalDetails?.data}
          closeModal={() => {
            setDeleteModalDetails(null);
          }}
          onSuccess={() => {
            loadData(filters, false, true);
          }}
        />
      </div>
    </>
  );
};

export default CfApprovalReports;
