import { getOfflineData } from "../../utils/offline-services";

import {
  AssessmentOutlined,
  NoteAddOutlined,
  PendingActionsOutlined,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GridViewIcon from "@mui/icons-material/GridView";

export const SIDE_MENU = () => {
  const user = getOfflineData("user");

  let options = [
    {
      label: "Dashboard",
      url: "/",
      value: "/",
      logo: <DashboardIcon sx={{ mr: 1 }} />,
      activeLogo: <DashboardIcon color="primary" sx={{ mr: 1 }} />,
    },
    {
      label: "Pending Approval",
      url: "/approval",
      value: "/approval",
      logo: <PendingActionsOutlined sx={{ mr: 1 }} />,
      activeLogo: <PendingActionsOutlined color="primary" sx={{ mr: 1 }} />,
    },
    {
      label: "Financial Dashboard",
      url: "/financial-dashboard",
      value: "/financial-dashboard",
      logo: <GridViewIcon sx={{ mr: 1 }} />,
      activeLogo: <GridViewIcon color="primary" sx={{ mr: 1 }} />,
    },
  ];
  if (user && user.role === "ADMIN") {
    options.splice(1, 0, {
      label: "Create CF",
      url: "/create-cf",
      value: "/create-cf",
      logo: <NoteAddOutlined sx={{ mr: 1 }} />,
      activeLogo: <NoteAddOutlined color="primary" sx={{ mr: 1 }} />,
    });
    options.push({
      label: "Setup",
      url: "/setup",
      value: "/setup",
      logo: <NoteAddOutlined sx={{ mr: 1 }} />,
      activeLogo: <NoteAddOutlined color="primary" sx={{ mr: 1 }} />,
    });
  }
  return [
    ...options,
    {
      label: "Reports",
      url: "/reports",
      value: "/reports",
      logo: <AssessmentOutlined sx={{ mr: 1 }} />,
      activeLogo: <AssessmentOutlined color="primary" sx={{ mr: 1 }} />,
    },
  ];
};
