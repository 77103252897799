/** @format */
import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";

import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { selectStoreType, storeTypeList } from "../Store/config";
import { selectType, convertedTypesToRequired } from "../Users/config";
import { userTypes, convertedTypes } from "../../utils/constants";
import { resetPasswordWhenEditDialogBoxIsClosed } from "./utils";
import CustomModal from "../../../common-utilities/core/modal";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import AutoCompleteMultiSelect from "../../../common-utilities/core/autocomplete-multiselect";
import CommonBtn from "../../../common-utilities/core/common-button";
import { CategoryDoc } from "../TicketCategory/sub-components/category-doc.subcomponent";
import { NewTicketCatFields } from "../TicketCategory/sub-components/new-fields";
import RegionUser from "../Department/region-user";

const TicketCategoryModal = ({
  name,
  escalationList,
  closeModalFun,
  editCategoryFormSubmit,
  fullDescription,
  parent,
  adminAndTechNetUsersList,
  userPrimaryContact,
  userSecondaryContact,
  userTertiaryContact,
  categoryId,
  escalationToUsersList,
  deptId,
  dept,
  departmentList,
  otherDetails,
  setOtherDetails,
  isActive,
}) => {
  const [department, setdepartment] = useState({ label: "", value: null });

  const setdepartmentHandler = (event) => {
    setdepartment((prevVal) => ({
      ...prevVal,
      label: event.label,
      value: event.value,
    }));
  };
  const [TicketCategory, setTicketCategory] = useState([]);
  const [escalationmatrixList, SetescalationmatrixList] = useState([]);
  const setDropDownValuesOfOtherDropDownsOnSelection = useCallback(
    (
      value,
      setotherDropdownListFunction1,
      setotherDropDownListFunction2,
      otherValue1,
      otherValue2,
      setOriginalListFunction
    ) => {
      const otherList1 = adminAndTechNetUsersList.filter((item) => {
        return item.value !== value && item.value !== otherValue2;
      });

      const otherList2 = adminAndTechNetUsersList.filter((item) => {
        return item.value !== value && item.value !== otherValue1;
      });
      setotherDropdownListFunction1(otherList1);
      setotherDropDownListFunction2(otherList2);
      if (setOriginalListFunction) {
        const originalList = adminAndTechNetUsersList.filter((item) => {
          return item.value !== otherValue2 && item.value !== otherValue1;
        });
        setOriginalListFunction(originalList);
      }
    },
    [adminAndTechNetUsersList]
  );
  const slaLevelLabels = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
  ];

  const [slaLevelValue, setslaLevelValue] = useState({
    label: "",
    value: null,
  });

  const [escalteTo, setEscalteTo] = useState({ label: "", value: null });

  const [categoryParent, setCategoryParent] = useState({ name: "", id: null });
  const [createNewParent, setcreateNewParent] = useState(false);
  const [parentError, setParentError] = useState(false);

  const [primaryContact, setPrimaryContact] = useState({
    label: "",
    value: null,
  });
  const [secondaryContact, setSecondaryContact] = useState({
    label: "",
    value: null,
  });
  const [tertiaryContact, setTertiaryContact] = useState({
    label: "",
    value: null,
  });
  const [primaryContactList, setPrimaryContactList] = useState([]);
  const [secondaryContactList, setSecondaryContactList] = useState([]);
  const [tertiaryContactList, setTertiaryContactList] = useState([]);

  useEffect(() => {
    if (dept && deptId) {
      setdepartment({ label: dept, value: deptId });
    }
  }, [dept, deptId]);

  useEffect(() => {
    const curprimaryvalue = userPrimaryContact ? userPrimaryContact.id : null;
    const curSecondaryValue = userSecondaryContact
      ? userSecondaryContact.id
      : null;
    const curTertiaryValue = userTertiaryContact
      ? userTertiaryContact.id
      : null;

    if (userPrimaryContact) {
      setPrimaryContact({
        label: userPrimaryContact.name,
        value: userPrimaryContact.id,
      });
    }
    if (userSecondaryContact) {
      setSecondaryContact({
        label: userSecondaryContact.name,
        value: userSecondaryContact.id,
      });
    }
    if (userTertiaryContact) {
      setTertiaryContact({
        label: userTertiaryContact.name,
        value: userTertiaryContact.id,
      });
    }

    setDropDownValuesOfOtherDropDownsOnSelection(
      curprimaryvalue,
      setSecondaryContactList,
      setTertiaryContactList,
      curSecondaryValue,
      curTertiaryValue,
      setPrimaryContactList
    );
  }, [
    userPrimaryContact,
    userSecondaryContact,
    userTertiaryContact,
    setDropDownValuesOfOtherDropDownsOnSelection,
  ]);

  const changeParentHandler = (event) => {
    setCategoryParent((prevVal) => ({
      ...prevVal,
      name: event.label,
      id: event.value,
    }));
  };
  useEffect(() => {
    if (parent) {
      setCategoryParent({ name: parent?.name || "", id: parent?.id });
      setcreateNewParent(true);
    } else {
      setCategoryParent({ name: "", id: null });
      setcreateNewParent(false);
    }
  }, [parent]);

  const [showCreateEscalation, setShowCreateEscalation] = useState(false);
  const [escalationObj, setEscalationObj] = useState({
    sla: "",
  });
  const [escList, setEscalationList] = useState(escalationList);

  useEffect(() => {
    if (escalationList) {
      setEscalationList(escalationList);
    }

    return () => {
      setEscalationList(escalationList);
    };
  }, [escalationList]);
  const [isName, setIsName] = useState(name);
  const [status, setStatus] = useState(isActive);
  const [desc, setDesc] = useState(fullDescription);
  const payload = {
    name: isName,
    desc: desc,
    escalationMatrix: escList,
    parent: categoryParent.id,
    primaryContact: primaryContact.value,
    secondaryContact: secondaryContact.value,
    tertiaryContact: tertiaryContact.value,
    isActive: status,
    dept: Number(department.value),
    ...otherDetails,
    storeType: otherDetails?.storeType?.map?.((item) => item.value) || [],
  };

  const addNameHandler = (event) => {
    setIsName(event.target.value);
  };
  const addDescHandler = (event) => {
    setDesc(event.target.value);
  };
  const escalationListHandler = (escalateTo, no) => {
    escList.push({ ...escalationObj, escalateTo, no });
    setEscalationObj((prevVal) => ({
      ...prevVal,
      sla: "",
    }));
    setShowCreateEscalation(false);
  };

  const getAllCategoriesWithParent = () => {
    const categoryFilter = {};
    if (categoryId) {
      categoryFilter.excludeCategory = categoryId;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.TICKET_CATEGORY_WITHOUT_PARENT}`,
      null,
      categoryFilter
    )
      .then((response) => {
        if (response) {
          const allcategorieswithparent = response.map((item) => {
            const { id, name } = item;
            return { value: id.toString(), label: name || "" };
          });
          setTicketCategory(allcategorieswithparent);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (parent) {
      getAllCategoriesWithParent();
    }
  }, [parent]);

  useEffect(() => {
    if (escalationToUsersList) {
      SetescalationmatrixList(escalationToUsersList);
    }
  }, [escalationToUsersList]);

  useEffect(() => {
    if (!showCreateEscalation) {
      setEscalteTo({ label: "", value: null });
    }
  }, [showCreateEscalation]);

  const selectEscalationTo = (event) => {
    const { label, value } = event;
    setEscalteTo({ label, value });
  };

  const onSlaClose = () => {
    setEscalteTo({ label: "", value: null });
    setShowCreateEscalation(false);
    setslaLevelValue({
      label: "",
      value: null,
    });
    setEscalationObj((prevVal) => ({
      ...prevVal,
      sla: "",
    }));
    closeModalFun();
  };

  return (
    <div style={{ opacity: "1" }}>
      {showCreateEscalation === false && (
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField
            fullWidth
            id="name"
            sx={{ my: 1 }}
            label="Name"
            multiline
            className="new-textfield"
            onChange={addNameHandler}
            value={isName}
            size="small"
          />

          <div className="d-flex">
            <FormControlLabel
              value="start"
              checked={createNewParent}
              className="createNewParentlabel"
              control={
                <Checkbox
                  color="primary"
                  onChange={() => {
                    const getAllCategoriesWithParentwhileSelectingCheckbox =
                      !createNewParent;
                    if (getAllCategoriesWithParentwhileSelectingCheckbox) {
                      getAllCategoriesWithParent();
                    }
                    setCategoryParent({ name: "", id: null });
                    setParentError(false);
                    setcreateNewParent(!createNewParent);
                  }}
                />
              }
              label="Is this a Subcategory ?"
              labelPlacement="start"
            />
          </div>

          {createNewParent && (
            <>
              <AutocompleteComponent
                label="Select Main Category"
                options={TicketCategory}
                onChange={(e, val) => {
                  changeParentHandler(val);
                }}
                disableClear={true}
                value={
                  categoryParent?.id
                    ? { label: categoryParent?.name, value: categoryParent.id }
                    : null
                }
                className="select-dropdown"
              />

              {parentError && (
                <span className="error-msg">Parent is required</span>
              )}
            </>
          )}

          <TextField
            fullWidth
            id="description"
            sx={{ my: 1 }}
            multiline
            rows={4}
            label="Description"
            onChange={addDescHandler}
            value={desc}
            type="desc"
            className="new-textfield"
            size="small"
          />
          <AutocompleteComponent
            onChange={(e, val) => {
              setdepartmentHandler(val);
            }}
            className="select-dropdown select-department"
            options={departmentList || []}
            value={department?.value ? department : null}
            label="Department"
            disableClear={true}
          />

          <span className="create-escalation">
            <p>Create Escalation</p>{" "}
            <AddCircleOutlineIcon
              onClick={() => setShowCreateEscalation(true)}
              style={{ cursor: "pointer" }}
            />
          </span>
          <div className="delete-escalation-div">
            {escList !== [] &&
              escList.map((esc, index) => {
                return (
                  <div className="delete-escalation">
                    <p>{`SLA - ${esc.sla}h `}</p>
                    <p>{`Level - L${esc.no} `}</p>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const filteredList = escList.filter(
                          (_, removalIndex) => {
                            return removalIndex !== index;
                          }
                        );

                        setEscalationList(filteredList);
                      }}
                    />
                  </div>
                );
              })}
          </div>
          <AutocompleteComponent
            value={primaryContact?.value ? primaryContact : null}
            className="select-dropdown"
            onChange={(e, event) => {
              setPrimaryContact({ label: event.label, value: event.value });
              setDropDownValuesOfOtherDropDownsOnSelection(
                event.value,
                setSecondaryContactList,
                setTertiaryContactList,
                secondaryContact.value,
                tertiaryContact.value
              );
            }}
            options={primaryContactList}
            label="Primary Contact"
            disableClear={true}
          />

          <AutocompleteComponent
            value={secondaryContact?.value ? secondaryContact : null}
            className="select-dropdown"
            onChange={(e, event) => {
              setSecondaryContact({ label: event.label, value: event.value });
              setDropDownValuesOfOtherDropDownsOnSelection(
                event.value,
                setPrimaryContactList,
                setTertiaryContactList,
                primaryContact.value,
                tertiaryContact.value
              );
            }}
            options={secondaryContactList}
            label="Secondary Contact"
            disableClear={true}
          />

          <AutocompleteComponent
            value={tertiaryContact?.value ? tertiaryContact : null}
            className="select-dropdown"
            onChange={(e, event) => {
              setTertiaryContact({ label: event.label, value: event.value });
              setDropDownValuesOfOtherDropDownsOnSelection(
                event.value,
                setPrimaryContactList,
                setSecondaryContactList,
                primaryContact.value,
                secondaryContact.value
              );
            }}
            options={tertiaryContactList}
            label="Tertiary Contact"
            disableClear={true}
          />
          <NewTicketCatFields
            mainSetData={setOtherDetails}
            data={otherDetails}
          />
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              value="start"
              checked={status}
              className="createNewParentlabel"
              fullWidth
              control={
                <Checkbox
                  color="primary"
                  onChange={() => {
                    setStatus(!status);
                  }}
                />
              }
              label="Is Category Active?"
              labelPlacement="start"
            />
          </Box>

          <CategoryDoc
            showModal={!showCreateEscalation}
            mainSetData={setOtherDetails}
            data={otherDetails}
          />
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            spacing={2}
            sx={{ my: 2 }}
          >
            <CommonBtn
              variant="contained"
              className="button-color"
              onClick={() => {
                if (createNewParent && categoryParent.id == null) {
                  parentError(true);
                  return;
                }
                editCategoryFormSubmit(payload);
              }}
              fullWidth
            >
              Save
            </CommonBtn>
            <CommonBtn variant="outlined" onClick={onSlaClose} fullWidth>
              Cancel
            </CommonBtn>
          </Stack>
        </Box>
      )}
      {showCreateEscalation === true && (
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField
            fullWidth
            id="sla"
            type="number"
            sx={{ my: 1 }}
            label="SLA in hours"
            onChange={(event) =>
              setEscalationObj((prevVal) => ({
                ...prevVal,
                sla: event.target.value,
              }))
            }
            value={escalationObj.sla}
            size="small"
            className="new-textfield"
          />

          <AutocompleteComponent
            value={slaLevelValue?.value ? slaLevelValue : null}
            options={slaLevelLabels}
            className="select-dropdown"
            onChange={(e, event) => {
              const { label, value } = event;
              setslaLevelValue({ label, value });
            }}
            label="Escalation Level"
            disableClear={true}
          />

          <AutocompleteComponent
            value={escalteTo?.value ? escalteTo : escalteTo.label}
            options={escalationmatrixList}
            className="select-dropdown"
            onChange={(e, val) => {
              selectEscalationTo(val);
            }}
            label="Escalate to"
            disableClear={true}
          />

          <Stack
            alignItems="flex-end"
            justifyContent="flex-end"
            direction="row"
            spacing={2}
            sx={{ my: 2 }}
          >
            <CommonBtn
              variant="contained"
              className="button-color"
              onClick={() =>
                escalationListHandler(
                  parseInt(escalteTo.value),
                  slaLevelValue.value
                )
              }
              fullWidth
              disabled={
                escalteTo.value === null ||
                escalationObj.sla === "" ||
                slaLevelValue.value === null
              }
            >
              Add
            </CommonBtn>
            <CommonBtn
              variant="outlined"
              onClick={() => {
                setShowCreateEscalation(false);
                setEscalationObj((prevVal) => ({
                  ...prevVal,
                  sla: "",
                }));
              }}
              fullWidth
            >
              Cancel
            </CommonBtn>
          </Stack>
        </Box>
      )}
    </div>
  );
};
const ActionIcons = ({
  id,
  type,
  loadData,
  owner,
  name,
  email,
  filters,
  ownerId,
  onCall,
  modalType,
  escList,
  fullDesc,
  departmentList,
  dept,
  parent,
  selectedDepartments,
  adminAndTechNetUsersList,
  userPrimaryContact,
  userSecondaryContact,
  userTertiaryContact,
  escalationToUsersList,
  deptId,
  getAllDepartmentNamesByStoreType,
  additionalDetails,
  isActive,
  departmentRegionData = {},
}) => {
  const [department, setdepartment] = useState({ label: "", value: null });
  const [formData, setFormData] = useState(departmentRegionData);
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [storeOwner, setStoreOwner] = useState({
    label: owner,
    value: ownerId,
  });
  const [otherDetails, setOtherDetails] = useState({});
  const [selectedDept, setSelectedDept] = useState(selectedDepartments);
  const [storeType, setStoreType] = useState(type);
  const [departmentStoreType, setdepartmentStoreType] = useState([]);
  const [editName, setEditName] = useState(name);
  const [editEmail, setEditEmail] = useState(email);
  const [userType, setUserType] = useState(type);
  const [onCallStatus, setOnCallStatus] = useState(onCall);
  const [ownerList, setOwnerList] = useState([]);
  const [showOnCall, setShowOnCall] = useState(false);
  const [password, setPassword] = useState("");
  const [isDataResetedInActionIcon, setisDataResetedInActionIcon] =
    useState(false);
  const showDeleteIcon = !(modalType === "STORE") && !(modalType === "USER");
  const setDefaultValuesForActionIcons = () => {
    if (!isDataResetedInActionIcon) {
      setOtherDetails(additionalDetails || {});
      setEditEmail(email);
      setStoreOwner({
        label: owner,
        value: ownerId,
      });

      setStoreType(type?.split?.("_")?.join?.(" ") || "");
      setEditName(name);
      setUserType(type);
      setFormData(departmentRegionData);

      const storeTypeMap = {};
      selectStoreType.forEach((item) => {
        storeTypeMap[item.value] = item.label;
      });
      if (type && Array.isArray(type)) {
        const allDepartmentStores = [];
        type.forEach((item) => {
          if (storeTypeMap[item]) {
            allDepartmentStores.push({
              label: storeTypeMap[item],
              value: item,
            });
          }
        });
        setdepartmentStoreType(allDepartmentStores);
      }

      if (modalType === "USER") {
        switch (type) {
          case userTypes.STORE_OWNER:
            setUserType(convertedTypes.STORE_OWNER);
            break;
          case userTypes.TECH_NET_USER:
            setUserType(convertedTypes.TECH_NET_USER);
            break;
          case userTypes.ADMIN:
            setUserType(convertedTypes.ADMIN);
            break;
          case userTypes.AGENT:
            setUserType(convertedTypes.AGENT);
          default:
            break;
        }
      }
      if (selectedDepartments) {
        setSelectedDept(selectedDepartments);
      }

      if (dept && deptId) {
        setdepartment({ label: dept, value: deptId });
      }

      if (
        userType === "TECH_NET_USER" ||
        userType === "ADMIN" ||
        userType === convertedTypes.TECH_NET_USER ||
        userType === convertedTypes.ADMIN
      ) {
        setShowOnCall(true);
      } else {
        setShowOnCall(false);
        setOnCallStatus(false);
      }
      if (onCall === "true") {
        setOnCallStatus(true);
      }
      if (onCall === "false") {
        setOnCallStatus(false);
      }
    } else {
      setisDataResetedInActionIcon(false);
    }
  };

  useEffect(() => {
    resetPasswordWhenEditDialogBoxIsClosed(edit, setPassword);
  }, [edit]);

  useEffect(() => {
    setDefaultValuesForActionIcons();
  }, [
    email,
    owner,
    ownerId,
    name,
    modalType,
    type,
    selectedDepartments,
    dept,
    deptId,
    onCall,
    additionalDetails,
    departmentRegionData,
  ]);

  //list down store owners
  useEffect(() => {
    if (edit && modalType === "STORE") {
      invokeApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.GET_USERS_WITH_FILTER}?type=STORE_OWNER`
      )
        .then((response) => {
          if (response) {
            response = response.map((user) => ({
              label: user.name,
              value: user.id,
            }));
            setOwnerList(response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [edit, modalType]);
  const editHandler = () => {
    const isEditingStoreAndWeHaveTOGetStoreData =
      storeTypeList.includes(type) && modalType === "STORE";
    if (isEditingStoreAndWeHaveTOGetStoreData) {
      setisDataResetedInActionIcon(true);
      if (getAllDepartmentNamesByStoreType) {
        getAllDepartmentNamesByStoreType(type || "");
      }
    }
    setEdit(true);
  };
  const deleteHandler = () => {
    setDel(true);
  };
  const onCallHandler = () => {
    setOnCallStatus(!onCallStatus);
  };
  const editNameHandler = (event) => {
    setEditName(event.target.value);
  };
  const editEmailHandler = (event) => {
    setEditEmail(event.target.value);
  };
  const editStoreTypeHandler = (event) => {
    setisDataResetedInActionIcon(true);
    setSelectedDept([]);
    if (getAllDepartmentNamesByStoreType) {
      getAllDepartmentNamesByStoreType(event?.value || "");
    }
    setStoreType(event.label);
  };
  const editStoreTypeInDepartment = (event) => {
    setdepartmentStoreType(event);
  };
  const editOwnerHandler = (event) => {
    setStoreOwner(event);
  };
  const editUserTypeHandler = (event) => {
    setUserType(event.label);
    if (
      event?.value === userTypes.STORE_OWNER ||
      event?.value === userTypes.AGENT
    ) {
      setShowOnCall(false);
      setOnCallStatus(false);
      return;
    }
    setShowOnCall(true);
  };
  const setdepartmentHandler = (event) => {
    setdepartment((prevVal) => ({
      ...prevVal,
      label: event.label,
      value: event.value,
    }));
  };
  const payload = {
    type: storeType,
    name: editName,
    email: editEmail,
    owner: storeOwner.value,
    departments: selectedDept?.map((dept) => dept.value),
  };
  const departmentPayload = {
    name: editName,
    allowedStoreTypes: departmentStoreType.map((item) => item.value),
    south: formData?.south?.value,
    east: formData?.east?.value,
    north: formData?.north?.value,
    west: formData?.west?.value,
    central: formData?.central?.value,
  };
  const userPayload = {
    name: editName,
    email: editEmail,
    type: convertedTypesToRequired[userType],
    onCall: onCallStatus,
  };
  //Edit Store
  const editStoreHandler = () => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_STORE}${id}`,
      payload
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success("Store Updated Successfully");
          loadData(filters);
          setEdit(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Edit Department
  const editDepartmentHandler = () => {
    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_DEPARTMENT}${id}`,
      departmentPayload
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success("Department Updated Successfully");
          loadData(filters);
          setEdit(false);
        }
      })
      .catch((err) => console.log(err));
  };
  //Delete Department
  const deleteDepartmentHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.UPDATE_DEPARTMENT}${id}`
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success("Department deleted successfully");
          loadData(filters);
          setDel(false);
        }
      })
      .catch((err) => console.log(err));
  };
  //Edit User
  const editUserHandler = () => {
    let additionalUserPayloadDetails = {};
    if (
      (userType === userTypes.TECH_NET_USER ||
        userType === convertedTypes.TECH_NET_USER ||
        userType === userTypes.AGENT ||
        userType === convertedTypes.AGENT) &&
      department.value
    ) {
      additionalUserPayloadDetails = { dept: department.value };
    }
    if (password.length > 0) {
      additionalUserPayloadDetails.password = password;
    }
    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.UPDATE_USER}${id}`, {
      ...userPayload,
      ...additionalUserPayloadDetails,
    })
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success("User Data Edited Successfully");
          loadData(filters);
          setEdit(false);
        }
      })
      .catch((err) => console.log(err));
  };

  //edit ticket category
  const editTicketCategoryHandler = (payload) => {
    let escalationMatrix = [];
    if (payload.escalationMatrix) {
      escalationMatrix = payload.escalationMatrix.map((item) => {
        let escalateTo = {};
        if (item.escalateTo) {
          if (typeof item.escalateTo === "object" && item.escalateTo) {
            escalateTo = { escalateTo: item.escalateTo.id };
          } else {
            escalateTo = { escalateTo: item.escalateTo };
          }
        }
        return { ...item, ...escalateTo };
      });
    }

    invokeApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.UPDATE_CATEGORY}${id}`,
      { ...payload, escalationMatrix }
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success("Ticket Category Updated Successfully");
          loadData(filters);
          setEdit(false);
        }
      })
      .catch((err) => console.log(err));
  };
  //delete ticket category
  const deleteCategoryHandler = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.UPDATE_CATEGORY}${id}`
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          toast.success("Ticket category deleted successfully");
          loadData(filters);
          setDel(false);
        }
      })
      .catch((err) => console.log(err));
  };
  let closeEditModal = () => {
    setEdit(false);
    setdepartment({ label: "", value: null });
    setDefaultValuesForActionIcons();
  };
  let CloseDeleteModal = () => {
    setDel(false);
  };
  const confirmDelete = () => {
    if (modalType === "DEPARTMENT") {
      deleteDepartmentHandler();
    }

    if (modalType === "CATEGORY") {
      deleteCategoryHandler();
    }
    setDel(false);
  };

  return (
    <div className="technet-action-icons">
      <Stack
        alignItems="flex-end"
        justifyContent="flex-end"
        direction="row"
        spacing={1}
      >
        <IconButton sx={{ p: 0 }} onClick={editHandler}>
          <EditIcon id="edit" />
        </IconButton>
        {showDeleteIcon && (
          <IconButton onClick={deleteHandler} id="delete" sx={{ p: 0 }}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      {edit && modalType === "STORE" && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          title="Edit Store"
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setDefaultValuesForActionIcons();
            closeEditModal();
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <div>
              <TextField
                fullWidth
                id="name"
                label="Name"
                className="new-textfield"
                sx={{ my: 1 }}
                onChange={editNameHandler}
                value={editName}
                size="small"
              />
            </div>

            <div>
              <TextField
                fullWidth
                id="email"
                label="Email"
                sx={{ my: 1 }}
                className="new-textfield"
                onChange={editEmailHandler}
                value={editEmail}
                size="small"
              />
            </div>
            <AutocompleteComponent
              label="Owner"
              onChange={(e, val) => {
                editOwnerHandler(val);
              }}
              className="select-dropdown"
              value={storeOwner?.value ? storeOwner : null}
              options={ownerList}
              disableClear={true}
            />

            <AutocompleteComponent
              label="Type"
              onChange={(e, val) => {
                editStoreTypeHandler(val);
              }}
              className="select-dropdown"
              value={
                selectStoreType.find((item) => item.label === storeType) || null
              }
              options={selectStoreType}
              disableClear={true}
            />

            <AutoCompleteMultiSelect
              options={departmentList || []}
              value={selectedDept}
              className="select-dropdown"
              placeholder="Select Departments"
              onChange={(e, item) => setSelectedDept(item)}
            />

            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={2}
              sx={{ my: 1 }}
            >
              <CommonBtn
                variant="contained"
                className="button-color"
                fullWidth
                onClick={editStoreHandler}
                size="medium"
              >
                Save
              </CommonBtn>
              <CommonBtn
                variant="outlined"
                fullWidth
                onClick={() => {
                  setEdit(false);
                  setDefaultValuesForActionIcons();
                }}
                size="medium"
              >
                Cancel
              </CommonBtn>
            </Stack>
          </Box>
        </CustomModal>
      )}
      {edit && modalType === "DEPARTMENT" && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          title="Edit Department"
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setDefaultValuesForActionIcons();
            closeEditModal();
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="name"
              label="Name"
              sx={{ my: 1 }}
              onChange={editNameHandler}
              value={editName}
              size="small"
              className="new-textfield"
            />

            <AutoCompleteMultiSelect
              value={departmentStoreType}
              options={selectStoreType}
              placeholder="Type"
              onChange={(e, val) => {
                editStoreTypeInDepartment(val);
              }}
            />
            <RegionUser formData={formData} setFormData={setFormData} />
            <Stack
              alignItems="flex-end"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
              sx={{ my: 2 }}
            >
              <CommonBtn
                size="medium"
                variant="contained"
                // className="button-color"
                fullWidth
                disabled={!editName?.trim() || departmentStoreType.length === 0}
                onClick={editDepartmentHandler}
              >
                Save
              </CommonBtn>
              <CommonBtn
                variant="outlined"
                size="medium"
                fullWidth
                onClick={() => {
                  setEdit(false);
                  setDefaultValuesForActionIcons();
                }}
              >
                Cancel
              </CommonBtn>
            </Stack>
          </Box>
        </CustomModal>
      )}
      {edit && modalType === "USER" && (
        <CustomModal
          title="Edit User"
          classesToPreventClosing={["MuiAutocomplete-option"]}
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setDefaultValuesForActionIcons();
            closeEditModal();
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <div>
              <TextField
                fullWidth
                id="name"
                sx={{ my: 1 }}
                label="Name"
                multiline
                onChange={editNameHandler}
                value={editName}
                size="small"
                className="new-textfield"
              />
            </div>
            <div>
              <TextField
                fullWidth
                id="email"
                sx={{ my: 1 }}
                label="Email"
                onChange={editEmailHandler}
                value={editEmail}
                type="email"
                size="small"
                className="new-textfield"
              />
            </div>
            <TextField
              fullWidth
              id="password"
              sx={{ my: 1 }}
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              type="password"
              className="new-textfield"
              size="small"
            />
            <AutocompleteComponent
              label="Type"
              onChange={(e, val) => {
                editUserTypeHandler(val);
              }}
              options={selectType}
              value={selectType.find((item) => item.label === userType) || null}
              disableClear={true}
            />

            {showOnCall && (
              <FormControlLabel
                label="On Call"
                control={
                  <Checkbox checked={onCallStatus} onChange={onCallHandler} />
                }
              />
            )}

            {(userType === "TECH_NET_USER" ||
              userType === convertedTypes.TECH_NET_USER ||
              userType === convertedTypes.AGENT ||
              userType === userTypes.AGENT) && (
              <AutocompleteComponent
                label="Department"
                value={department?.value ? department : null}
                options={departmentList || []}
                className="select-dropdown select-department"
                onChange={(e, val) => {
                  setdepartmentHandler(val);
                }}
                disableClear={true}
              />
            )}

            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={2}
              sx={{ my: 2 }}
            >
              <CommonBtn
                variant="contained"
                size="medium"
                className="button-color"
                fullWidth
                onClick={editUserHandler}
              >
                Save
              </CommonBtn>
              <CommonBtn
                variant="outlined"
                fullWidth
                size="medium"
                onClick={() => {
                  setEdit(false);
                  setDefaultValuesForActionIcons();
                }}
              >
                Cancel
              </CommonBtn>
            </Stack>
          </Box>
        </CustomModal>
      )}
      {edit && modalType === "CATEGORY" && (
        <CustomModal
          title="Edit Category"
          classesToPreventClosing={["MuiAutocomplete-option"]}
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setDefaultValuesForActionIcons();
            closeEditModal();
          }}
        >
          <TicketCategoryModal
            categoryId={id}
            name={name}
            escalationList={escList}
            closeModalFun={closeEditModal}
            editCategoryFormSubmit={editTicketCategoryHandler}
            fullDescription={fullDesc}
            parent={parent}
            adminAndTechNetUsersList={adminAndTechNetUsersList}
            userPrimaryContact={userPrimaryContact}
            userSecondaryContact={userSecondaryContact}
            userTertiaryContact={userTertiaryContact}
            escalationToUsersList={escalationToUsersList}
            deptId={deptId}
            dept={dept}
            departmentList={departmentList}
            otherDetails={otherDetails}
            setOtherDetails={setOtherDetails}
            isActive={isActive}
          />
        </CustomModal>
      )}
      {del && (
        <CustomModal
          title="Confirm Delete"
          classesToPreventClosing={["MuiAutocomplete-option"]}
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setDefaultValuesForActionIcons();
            CloseDeleteModal();
          }}
        >
          <h3 className="d-flex">Are you sure ?</h3>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            spacing={2}
          >
            <CommonBtn
              variant="outlined"
              size="medium"
              onClick={() => {
                setDel(false);
                setDefaultValuesForActionIcons();
              }}
            >
              Cancel
            </CommonBtn>
            <CommonBtn
              size="medium"
              variant="contained"
              color="error"
              onClick={confirmDelete}
            >
              Delete
            </CommonBtn>
          </Stack>
        </CustomModal>
      )}
    </div>
  );
};
export default ActionIcons;
