import React, { useState, useEffect } from "react";
import "./index.scss";
import AppCard from "../../core/cards";
import BulletinComponent from "../../components/display-blogs";
import { Grid, Button, Box } from "@mui/material";
import { getOfflineData } from "../../utils/offline-services";
import { INTRANETHOSTNAME } from "../../packages/common-utilities/utils/end-points";
import { REST_URLS } from "../../packages/common-utilities/utils/end-points/intranet";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import ArTable from "../AR-table";
import { getLimitsForUserLoggedIn } from "../../utils/common-apis";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import CustomModal from "../../packages/common-utilities/core/modal";
import BreadList from "../bread-list";
const AllowedApps = () => {
  const [listAllowedApps, setAllowedApps] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [showArData, setShowArData] = useState(false);
  const [showArApp, setShowArApp] = useState(false);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [predictData, setPredictData] = useState({
    isOpen: false,
    data: [],
  });
  const [categoryList, setCategoryList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedCycle, setSelectedCycle] = useState("");
  const [blogFilter, setBlogFilter] = useState({
    category: "",
    startDate: "",
    endDate: "",
  });
  const openFilters = Boolean(anchorFilter);
  const loadData = () => {
    const params = {
      sortBy: "-createdAt",
      limit: 50,
    };
    if (blogFilter?.category) {
      params["category"] = blogFilter.category;
    }
    if (blogFilter?.startDate) {
      params["startDate"] = blogFilter.startDate;
    }
    if (blogFilter?.endDate) {
      params["endDate"] = blogFilter.endDate;
    }
    plainApi("GET", `${INTRANETHOSTNAME}${REST_URLS.GET_BLOGS}`, null, params)
      .then((response) => {
        if (response) {
          const role = getOfflineData("user")?.role;
          const results = response.results
            .map((ele) => {
              if (role === "franchise") {
                if (ele.showToFranchise) {
                  return ele;
                }
              } else {
                return ele;
              }
            })
            .filter((ele) => ele && ele);
          setBlogs(results);
        } else {
          console.log("Fetching blogs failed");
        }
      })
      .catch((err) => {
        console.log("Fetching blogs failed");
      });
  };
  useEffect(() => {
    getLimitsForUserLoggedIn((limits) => {
      setAllowedApps(limits);
    });
  }, []);
  const getStoreData = () => {
    const userId = getOfflineData("user")?.userId;
    const [storeNumber, sequenceNumber, satelliteNumber] = userId.split("-");
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.STORE_BY_STORE_NUMBER}`,
      null,
      { storeNumber, sequenceNumber, satelliteNumber }
    )
      .then((res) => {
        setShowArApp(res?.storeType === "FOFO");
      })
      .catch((err) => {
        setShowArApp(false);
      });
  };
  const getPredictBread = () => {
    const storeNumber = getOfflineData("user")?.userId;
    const url = `${REST_URLS.PREDICT_BREAD}`;

    const currentHour = new Date().getHours();
    const isCycle1 = currentHour >= 17 || currentHour < 7; // If it's after 5 PM or before 7 AM, it's Cycle 1

    const cycle = isCycle1 ? "Cycle 1 (7 AM - 5 PM)" : "Cycle 2 – 5 PM to 3 AM";
    const date = isCycle1
      ? moment().add(1, "day").format("YYYY-MM-DD") // Use next day's date for Cycle 1
      : moment().format("YYYY-MM-DD"); // Use today's date for Cycle 2

    const params = {
      date: date,
      cycle: cycle,
      storeid: storeNumber,
    };

    const headers = { Authorization: "d3e7a4e9-1a64d8d2a9e9" };
    if (selectedCycle && selectedDate) {
      params.date =
        selectedDate === "Tomorrow"
          ? moment().add(1, "day").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
      params.cycle =
        selectedCycle === "Cycle 2"
          ? "Cycle 2 – 5 PM to 3 AM"
          : "Cycle 1 (7 AM - 5 PM)";
    }

    axios
      .get(url, { params, headers })
      .then((response) => {
        setPredictData((prevVal) => ({
          ...prevVal,
          data: response.data || [],
        }));
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const role = getOfflineData("user")?.role;
    if (role === "franchise") {
      getStoreData();
      getPredictBread();
    }
  }, []);
  useEffect(() => {
    const role = getOfflineData("user")?.role;
    if (role === "franchise") {
      getPredictBread();
    }
  }, [selectedCycle, selectedDate]);
  useEffect(() => {
    loadData();
  }, []);
  const handleClickFilter = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorFilter(null);
  };
  const initializeCall = () => {
    invokeApi("GET", `${INTRANETHOSTNAME}${REST_URLS.GET_CATEGORY}`)
      .then((response) => {
        if (response) {
          let cat = response.map((iter) => {
            return {
              label: iter.name,
              value: iter.id,
            };
          });
          setCategoryList(cat);
        }
      })
      .catch((err) => {
        toast.error("Fetching category list failed");
      });
  };
  useEffect(() => {
    initializeCall();
  }, []);
  return (
    <div>
      {!showArData && (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 2,
            }}
          >
            <h6 className="title-header allowed-apps ">Subway Broadcast</h6>
            <Button
              onClick={() =>
                setPredictData((prevVal) => ({ ...prevVal, isOpen: true }))
              }
              variant="outlined"
              size="small"
            >
              Bread Prediction
            </Button>
          </Box>

          {blogs?.length ? (
            <BulletinComponent
              data={blogs}
              setBlogFilter={setBlogFilter}
              blogFilter={blogFilter}
            />
          ) : (
            <>
              <p className="no-broadcast">No Broadcast</p>
            </>
          )}

          <h6 className="title-header allowed-apps ">My Access</h6>
          <div className="allowed-apps">
            <Grid container md={12}>
              <Grid container md={12}>
                <div className="apps-con">
                  {listAllowedApps?.length > 0 &&
                    listAllowedApps.map((app) => {
                      return <AppCard appDetails={app} />;
                    })}
                  {!showArApp && listAllowedApps?.length < 1 && (
                    <h2>No Apps Available</h2>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {showArData && <ArTable showArDataFun={setShowArData} />}
      {predictData?.isOpen && (
        <CustomModal
          onClose={() =>
            setPredictData((prevVal) => ({
              ...prevVal,
              isOpen: false,
            }))
          }
          classesToPreventClosing={[
            "MuiButtonBase-root",
            "MuiMenuItem-root",
            "MuiAutocomplete-option",
            "Mui-focused",
          ]}
          bodyClass="bread-modal-padding"
        >
          <BreadList
            data={predictData.data}
            selectedDate={selectedDate}
            selectedCycle={selectedCycle}
            setSelectedCycle={setSelectedCycle}
            setSelectedDate={setSelectedDate}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default AllowedApps;
