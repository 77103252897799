import React, { useEffect, useState } from "react";
import { FINANCE_HOSTNAME } from "../../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi } from "../../../utils/http-service";
import { getHeaderConfig } from "./helper";
import "./index.scss";
import MuiTable from "../../../../common-utilities/core/mui-table";
import { FINANCE_REST_URLS } from "../../../../common-utilities/utils/end-points/finance";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import DateRangeFilter from "../../../../common-utilities/core/date-range-filter";
import { downloadReports } from "./utils";
import PoFilters from "../../purchase-order/po-filters";
import { grnStatusList } from "./constants";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../../common-utilities/utils/time";
const GRNList = ({ status }) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    sortBy: "-createdAt",
  });

  useEffect(() => {
    if (status) {
      loadData(filters);
    }
  }, [status]);

  const loadData = (apiFilters, download) => {
    let params = {
      ...apiFilters,
    };
    if (params.startDate) {
      params.startDate = getStartOfDay(params.startDate);
    }
    if (params.endDate) {
      params.endDate = getEndOfDay(params.endDate);
    }
    if (status !== "all") {
      params.status = status;
    }
    params.populateSite = true;
    if (searchKey) {
      params.searchKey = searchKey;
    }

    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GRN_LIST}`,
      null,
      params
    )
      .then((response) => {
        if (download) {
          downloadReports(response?.results || [], status);
          return;
        }
        setBudgetList(response);
      })
      .catch((err) => {});
  };

  const viewBudget = (data, type) => {
    const { purchaseOrderId, approvalId = {}, invoiceDataId } = data;
    if (type === "EDIT") {
      navigate(`grn-order/edit/${data.id}?poId=${purchaseOrderId.id}`);
    } else if (approvalId && approvalId.id) {
      navigate(
        `invoice-data/${invoiceDataId}?approvalId=${approvalId.id}&from=GRN`
      );
    } else {
      navigate(`grn-order/${data.id}`);
    }
  };

  return (
    <div className="budget-list">
      <div className="filter-row">
        <TextField
          placeholder="Search by GRN/PO Number"
          value={searchKey}
          size="small"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <PoFilters
          filters={filters}
          setFilters={setFilters}
          showStatus={true}
          statusList={grnStatusList}
          disableStatus={status !== "all"}
        />

        <DateRangeFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateRange={setFilters}
        />
        <Button
          variant="outlined"
          onClick={() => {
            setFilters({
              ...filters,
              page: 1,
            });
            loadData({
              ...filters,
              page: 1,
            });
          }}
        >
          Search
        </Button>

        <Button
          variant="outlined"
          disabled={!budgetList.totalResults}
          onClick={() => {
            loadData(
              {
                ...filters,
                page: 1,
                limit: budgetList.totalResults,
              },
              true
            );
          }}
        >
          Download
        </Button>
      </div>

      <MuiTable
        columnsList={getHeaderConfig(viewBudget, status)}
        dataList={budgetList.results || []}
        filters={filters}
        pageCount={budgetList.totalPages}
        onChange={(page) => {
          setFilters({
            ...filters,
            page,
          });
          loadData({
            ...filters,
            page,
          });
        }}
      />
    </div>
  );
};

export default GRNList;
