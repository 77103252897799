import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import * as XLSX from "xlsx";

const AgeingReport = () => {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({});

  // Mapping between numeric months and their string equivalents
  const monthMapping = {
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
    1: "January",
    2: "February",
    3: "March",
  };
  const getFinancialYearMonths = () => {
    const currentMonth = new Date().getMonth() + 1;
    const monthsInOrder = [
      ...Object.keys(monthMapping).slice(3),
      ...Object.keys(monthMapping).slice(0, 3),
    ];
    const financialYearMonths = monthsInOrder.slice(
      0,
      monthsInOrder.indexOf(String(currentMonth)) + 1
    );
    return financialYearMonths.map((month) => monthMapping[month]);
  };

  const months = getFinancialYearMonths();

  const getAgeingData = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.AGEING_REPORT}`)
      .then((res) => {
        if (!res.message) {
          setData(res);
          calculateTotals(res);
        }
      })
      .catch((err) => console.error(err));
  };

  const calculateTotals = (data) => {
    const rowTotals = {};
    const columnTotals = {
      "10-30": 0,
      "31-60": 0,
      "61-90": 0,
      "91-120": 0,
      "121-150": 0,
      "151-180": 0,
      "180+": 0,
      GrandTotal: 0,
    };

    data.forEach((item) => {
      const monthName = monthMapping[item.month];
      if (!monthName) return;

      let rowTotal = 0;
      Object.keys(columnTotals).forEach((key) => {
        if (item[key]) {
          columnTotals[key] += item[key];
          rowTotal += item[key];
        }
      });
      rowTotals[monthName] = rowTotal;
      columnTotals.GrandTotal += rowTotal;
    });

    setTotals({ rowTotals, columnTotals });
  };
  const ageingCategories = [
    "10-30",
    "31-60",
    "61-90",
    "91-120",
    "121-150",
    "151-180",
    "180+",
  ];
  const exportDataToExcel = (data, months) => {
    if (!data || data.length === 0) {
      console.warn("No data to export.");
      return;
    }
    const headers = ["Ageing Bracket", ...months, "Grand Total"];
    const rows = [];

    ageingCategories.forEach((ageBracket) => {
      const row = [ageBracket];
      months.forEach((month) => {
        const monthData = data.find(
          (item) => monthMapping[item.month] === month
        );
        row.push(monthData ? monthData[ageBracket] || 0 : 0);
      });
      row.push(totals?.columnTotals?.[ageBracket] || 0);
      rows.push(row);
    });
    const totalRow = ["Grand Total"];
    months.forEach((month) => {
      const monthData = data.find((item) => monthMapping[item.month] === month);
      totalRow.push(monthData ? totals?.rowTotals[month] || 0 : 0);
    });
    totalRow.push(totals?.columnTotals?.GrandTotal || 0);
    rows.push(totalRow);
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Ageing Report");
    XLSX.writeFile(wb, "Ageing_Report.xlsx");
  };

  useEffect(() => {
    getAgeingData();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        style={{ color: "green" }}
      >
        Ageing of Complaints
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => exportDataToExcel(data, months)}
          style={{ marginBottom: "10px" }}
        >
          Download
        </Button>
      </Box>

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
              >
                Ageing Bracket
              </TableCell>
              {months.map((month, index) => (
                <TableCell
                  key={index}
                  align="center"
                  style={{ backgroundColor: "#4CAF50", color: "white" }}
                >
                  {month}
                </TableCell>
              ))}
              <TableCell
                align="center"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
              >
                Grand Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ageingCategories.map((ageBracket) => (
              <TableRow key={ageBracket}>
                <TableCell>{ageBracket} days</TableCell>
                {months.map((month, index) => {
                  const monthData = data.find(
                    (item) => monthMapping[item.month] === month
                  );
                  return (
                    <TableCell key={index} align="center">
                      {monthData ? monthData[ageBracket] || 0 : 0}
                    </TableCell>
                  );
                })}
                <TableCell align="center">
                  {totals?.columnTotals?.[ageBracket] || 0}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: "#A0522D",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Grand Total
              </TableCell>
              {months.map((month, index) => {
                const monthData = data.find(
                  (item) => monthMapping[item.month] === month
                );
                return (
                  <TableCell
                    key={index}
                    align="center"
                    style={{
                      backgroundColor: "#A0522D",
                      color: "white",
                    }}
                  >
                    {monthData ? totals?.rowTotals[month] || 0 : 0}
                  </TableCell>
                );
              })}
              <TableCell
                align="center"
                style={{
                  backgroundColor: "#A0522D",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {totals?.columnTotals?.GrandTotal || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AgeingReport;
