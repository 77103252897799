import React, { useState } from "react";
import "./index.scss";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { plainApi } from "../../utils/http-service";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { INTRANETHOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/tech-net";

const RegionUser = ({ formData = {}, setFormData }) => {
  const [nameOptions, setNameOptions] = useState([]);
  const onInputChange = (e, value) => {
    if (value?.trim() && e?.target?.value) {
      getuserNameList(value, "name");
    }
  };

  const getuserNameList = (searchTerm, fieldName) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    let params = {
      [fieldName]: searchTerm,
    };
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${INTRANETHOSTNAME}${REST_URLS.SEARCH_BY_USER_NAME}`,
        null,
        params
      ).then((res) => {
        if (fieldName === "name") {
          const result = res.map((ele) => ({
            label: ele?.name,
            value: ele?._id,
          }));
          setNameOptions(result);
        }
      });
    }, 100);
  };
  return (
    <div>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" textAlign="start">
          Region Users
        </Typography>
        <Box className="region-user-con">
          <p>North</p>
          <Autocomplete
            label="Search By Name"
            onInputChange={onInputChange}
            onChange={(e, val) => {
              setFormData((prevVal) => ({ ...prevVal, north: val }));
            }}
            options={nameOptions}
            size="small"
            value={formData?.north || null}
            renderInput={(params) => (
              <TextField {...params} label="Select User" fullWidth />
            )}
          />
        </Box>
        <Box className="region-user-con">
          <p>South</p>
          <Autocomplete
            label="Search By Name"
            onInputChange={onInputChange}
            onChange={(e, val) => {
              setFormData((prevVal) => ({ ...prevVal, south: val }));
            }}
            options={nameOptions}
            size="small"
            value={formData?.south || null}
            renderInput={(params) => (
              <TextField {...params} label="Select User" fullWidth />
            )}
          />
        </Box>
        <Box className="region-user-con">
          <p>East</p>
          <Autocomplete
            label="Search By Name"
            onInputChange={onInputChange}
            onChange={(e, val) => {
              setFormData((prevVal) => ({ ...prevVal, east: val }));
            }}
            options={nameOptions}
            size="small"
            value={formData?.east || null}
            renderInput={(params) => (
              <TextField {...params} label="Select User" fullWidth />
            )}
          />
        </Box>
        <Box className="region-user-con">
          <p>West</p>
          <Autocomplete
            label="Search By Name"
            onInputChange={onInputChange}
            onChange={(e, val) => {
              setFormData((prevVal) => ({ ...prevVal, west: val }));
            }}
            options={nameOptions}
            size="small"
            value={formData?.west || null}
            renderInput={(params) => (
              <TextField {...params} label="Select User" fullWidth />
            )}
          />
        </Box>
        <Box className="region-user-con">
          <p>Central</p>
          <Autocomplete
            label="Search By Name"
            onInputChange={onInputChange}
            onChange={(e, val) => {
              setFormData((prevVal) => ({ ...prevVal, central: val }));
            }}
            options={nameOptions}
            size="small"
            value={formData?.central || null}
            renderInput={(params) => (
              <TextField {...params} label="Select User" fullWidth />
            )}
          />
        </Box>
      </Box>
    </div>
  );
};

export default RegionUser;
