import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { dateFun } from "../../../common-utilities/utils";
import { cocoAcqKeyNames } from "../../utils/constants";
import { lakhConversion } from "../../../common-utilities/utils/misc2";
import { ApproverConfig } from "../reports/config/approval-table.config";

export const getHeaderConfig = (
  showApproveButton,
  approveFormFunction,
  showRejectedBy
) => {
  let headerConfig = [];
  if (showApproveButton) {
    headerConfig.push({
      label: "Approval Id",
      id: "id",
    });
  }

  headerConfig.push(
    {
      label: "Restaurant Id",
      id: "restaurantId",
    },
    {
      label: "Restaurant Name",
      id: "restaurantName",
    }
  );
  if (showApproveButton) {
    headerConfig.push(
      {
        label: "Creation Date",
        id: "createdAt",
        valueConversionLogic: (date) => dateFun(date, true),
      },
      {
        label: "Type",
        id: "storeType",
      },
      ApproverConfig(1),
      ApproverConfig(2),
      ApproverConfig(3),
      ApproverConfig(4),
      ApproverConfig(5),
      ApproverConfig(6),
      {
        label: cocoAcqKeyNames.adsTtm,
        id: "businessDetails.adsTtm",
      },
      {
        label: cocoAcqKeyNames.ads,
        id: "ads",
      },
      {
        label: cocoAcqKeyNames.ttmEbitdaPercentage,
        id: "businessDetails.ttmEbitdaPercentage",
      },
      {
        label: cocoAcqKeyNames.ebitdaPercentage,
        id: "businessDetails.ebitdaPercentage",
      },
      {
        label: cocoAcqKeyNames.ttmAdjustedEbitdaMultiple,
        id: "businessDetails.ttmAdjustedEbitdaMultiple",
      },
      {
        label: cocoAcqKeyNames.forward1YearEbitdaMultiple,
        id: "businessDetails.forward1YearEbitdaMultiple",
      },
      {
        label: cocoAcqKeyNames.deliveryPercentage,
        id: "deliveryPercentage",
      },
      {
        label: cocoAcqKeyNames.occupancyPercentage,
        id: "occupancyPercentage",
      },
      {
        label: cocoAcqKeyNames.labourPercentage,
        id: "labourPercentage",
      },
      {
        label: cocoAcqKeyNames.totalCapex,
        id: "totalCapex",
      }
    );
  }
  if (!showApproveButton) {
    headerConfig.push(
      ApproverConfig(1),
      ApproverConfig(2),
      ApproverConfig(3),
      ApproverConfig(4),
      ApproverConfig(5),
      ApproverConfig(6),
      {
        label: "Region",
        id: "cluster",
      },
      {
        label: "Format",
        id: "format",
      },
      {
        label: "ADS (Avg Daily Sales)",
        id: "ads",
      },
      {
        label: "Labour(%)",
        id: "labourPercentage",
      },
      {
        label: "Occupancy(%)",
        id: "occupancyPercentage",
      },
      {
        label: "EBITDA(%)",
        id: "ebitdaPercentage",
      },
      {
        label: "Delivery(%)",
        id: "deliveryPercentage",
      },
      {
        label: "Total Capex (INR lacs)",
        id: "totalCapex",
      }
    );
  }

  headerConfig.push({
    label: "Payback (in months)",
    id: "paybackInMonths",
  });
  if (!showApproveButton) {
    headerConfig.push({
      label: "View files",
      id: "view files",
      render: (data, onClick) => {
        return (
          <RemoveRedEyeIcon
            onClick={() => onClick(data)}
            sx={{ cursor: "pointer" }}
          />
        );
      },
    });
  } else {
    headerConfig.push({
      label: cocoAcqKeyNames.grossOfferExtendedA,
      id: "businessDetails.grossOfferExtendedA",
      valueConversionLogic: (value) => lakhConversion(value, false, true),
    });
  }

  if (showApproveButton) {
    headerConfig.push(
      {
        label: "View files",
        id: "view files",
        render: (data, onClick) => {
          return (
            <RemoveRedEyeIcon
              onClick={() => onClick(data)}
              sx={{ cursor: "pointer" }}
            />
          );
        },
      },
      {
        label: "Action",
        id: "approve",
        render: (data, onClick) => {
          return (
            <div className="approval-buttons">
              <Button
                variant="contained"
                size="small"
                sx={{ mb: 0.5, mt: 0.5 }}
                onClick={() => {
                  if (approveFormFunction) {
                    approveFormFunction(data);
                  }
                }}
              >
                Approve
              </Button>

              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ mb: 0.5, mt: 0.5 }}
                onClick={() => {
                  onClick(data, "reject approval");
                }}
              >
                Reject
              </Button>
            </div>
          );
        },
      }
    );
  }

  if (showRejectedBy) {
    headerConfig.splice(
      headerConfig.length - 1,
      0,
      {
        label: "Rejected By",
        id: "rejected",
        render: (data) => {
          return (
            <div id={data?.id} className="si-cell">
              {data?.rejectedBy?.name}
            </div>
          );
        },
      },
      {
        label: "Rejected At",
        id: "rejectedAt",
        render: (data) => {
          return (
            <div id={data?.id} className="si-cell">
              {dateFun(data?.rejectedAt, true)}
            </div>
          );
        },
      }
    );
  }
  return headerConfig;
};
