import TableCell from "@mui/material/TableCell";
import "./index.scss";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import { formatDate } from "../../../common-utilities/utils";
import { DATE_FORMATS } from "../../../common-utilities/utils/constants";

export const getHeaderConfig = (role) => {
  const result = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Store Number",
      id: "storeNumber",
    },
    {
      label: "Satellite Number",
      id: "satelliteNumber",
    },
    {
      label: "Sequence Number",
      id: "sequenceNumber",
    },
    {
      label: "Store Name",
      id: "ownerName",
    },
    {
      label: "Store Display Name",
      id: "restaurantName",
    },
    {
      label: "Store Type",
      id: "storeType",
    },
    {
      label: "Format",
      id: "format",
    },
    {
      label: "Sub Format",
      id: "subFormat",
    },
    {
      label: "Status",
      id: "status",
    },
    {
      label: "Address ",
      id: "address",
    },
    {
      label: "City",
      id: "city",
    },
    {
      label: "State",
      id: "state",
    },
    {
      label: "Area",
      id: "area",
    },
    {
      label: "Store Opening Date",
      id: "storeOpeningDate",
      valueConversionLogic: (val) =>
        formatDate(val, DATE_FORMATS["DD-MM-YYYY"]),
    },
    {
      label: "Region",
      id: "region",
    },
    {
      label: "Country",
      id: "country",
    },
    {
      label: "Pincode",
      id: "pinCode",
    },
    {
      label: "Latitude ",
      id: "latitude",
    },
    {
      label: "Longitude",
      id: "longitude",
    },
    {
      label: "AM Name",
      id: "am",
      render: (data, onClick) => {
        return <div>{data?.am?.name}</div>;
      },
    },
    {
      label: "AM Email",
      id: "am",
      render: (data, onClick) => {
        return <div>{data?.am?.email}</div>;
      },
    },
    {
      label: "RM Name",
      id: "rm",
      render: (data, onClick) => {
        return <div>{data?.rm?.name}</div>;
      },
    },
    {
      label: "RM Email",
      id: "rm",
      render: (data) => {
        return <div>{data?.rm?.email}</div>;
      },
    },
    {
      label: "Dc Id",
      id: "dcid",
      render: (data) => {
        return <div>{data?.dc?.id}</div>;
      },
    },
    {
      label: "DC Name",
      id: "dcname",
      render: (data) => {
        return <div>{data?.dc?.name}</div>;
      },
    },
    {
      label: "DC Email",
      id: "dcemail",
      render: (data) => {
        return <div>{data?.dc?.email}</div>;
      },
    },
    {
      label: "Store Email",
      id: "email",
    },
    {
      label: "FZ Name",
      id: "fzName",
    },
    {
      label: "PAN",
      id: "pan",
    },
    {
      label: "GST",
      id: "gstn",
    },
    {
      label: "Operating Entity Name",
      id: "franchiseBusinessName",
    },
    {
      label: "Contact Number",
      id: "contactNumber",
    },

    {
      label: "Tier-City",
      id: "tierCity",
    },
    {
      label: "LSM Status",
      id: "lsmStatus",
    },
    {
      label: "Coffee Status",
      id: "coffeeStatus",
    },
    {
      label: "Outlier",
      id: "typeOfDecor",
    },
    {
      label: "Electricity Unit Rate",
      id: "electricityChargePerUnit",
    },
    {
      label: "Conversion Factor",
      id: "conversionFactor",
    },
    {
      label: "Count Of Screens",
      id: "countOfScreens",
    },
    {
      label: "Store Covers",
      id: "storeCovers",
    },
    {
      label: "DMB Type",
      id: "dmbType",
    },
    {
      label: "Screens Format",
      id: "screensFormat",
    },

    {
      label: "FS Team Member",
      id: "firstLevelEscalation",
      render: (data) => {
        return (
          <span key="firstLevelEscalation">
            {data?.firstLevelEscalation?.email}
          </span>
        );
      },
    },
  ];
  if (role === "admin") {
    result.push({
      label: "Actions",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <Stack
              direction="row"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <IconButton
                sx={{ pt: 0.5, pb: 0.5 }}
                onClick={() => onClick("update store details", data)}
              >
                <EditIcon id="edit" color="primary" />
              </IconButton>
            </Stack>
          </div>
        );
      },
    });
  }
  return result;
};
export const REGIONS_LIST = [
  { label: "North", value: "north" },
  { label: "South", value: "south" },
  { label: "East", value: "east" },
  { label: "Central", value: "central" },
  { label: "West", value: "west" },
];
export const STORE_TYPE = [
  { label: "COCO BUILT", value: "COCO_BUILT" },
  { label: "COCO ACQUIRED", value: "COCO_ACQUIRED" },
  { label: "FOFO", value: "FOFO" },
];
export const STORE_STATUS_LIST = [
  { label: "Temp Closure", value: "Temp Closure" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];
export const UserRoles = {
  areaManger: "Area Manager",
  regionalManger: "Regional Manger",
};
export const DMB_TYPE = [
  { label: "DMB", value: "DMB" },
  { label: "Translites", value: "Translites" },
];
export const SCREEN_FORMAT = [
  { label: "Vertical", value: "Vertical" },
  { label: "Horizontal", value: "Horizontal" },
  { label: "Mixed", value: "Mixed" },
];
export const STORE_TYPE_MAP = {
  "COCO BUILT": "COCO_BUILT",
  "COCO ACQUIRED": "COCO_ACQUIRED",
  FOFO: "FOFO",
};

export const FORMAT_LIST = [
  { label: "High street", value: "High street" },
  { label: "Mall", value: "Mall" },
  { label: "Non Traditional", value: "Non Traditional" },
];
export const SUB_FORMAT_LIST = [
  { label: "Airport", value: "Airport" },
  { label: "Food Court", value: "Food Court" },
  { label: "Highway", value: "Highway" },
  { label: "High Street", value: "High Street" },
  { label: "Hospital", value: "Hospital" },
  { label: "Institute", value: "Institute" },
  { label: "Mall", value: "Mall" },
  { label: "Tech Park", value: "Tech Park" },
  { label: "Metro Station", value: "Metro Station" },
];

export const CIRCLE_LIST = [
  { label: "AP&TS", value: "AP&TS" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Bihar, Jharkhand & Orissa", value: "Bihar, Jharkhand & Orissa" },
  { label: "Delhi", value: "Delhi" },
  { label: "EDNGF", value: "EDNGF" },
  { label: "GG", value: "GG" },
  { label: "Gujarat, MP & CG", value: "Gujarat, MP & CG" },
  { label: "Mumbai", value: "Mumbai" },
  {
    label: "North Punjab, JK HP @ Jallandhar",
    value: "North Punjab, JK HP @ Jallandhar",
  },
  { label: "Raj & Har", value: "Raj & Har" },
  { label: "ROK and Goa", value: "ROK and Goa" },
  { label: "RoMH", value: "RoMH" },
  { label: "South punjab @  Chandigarh", value: "South punjab @  Chandigarh" },
  { label: "TBU", value: "TBU" },
  { label: "TN&K", value: "TN&K" },
  { label: "Up&UK", value: "Up&UK" },
  { label: "WB & NE", value: "WB & NE" },
];
export const CAT_LIST = [
  { label: "COCO Acquired Cat-1 / FY23", value: "COCO Acquired Cat-1 / FY23" },
  { label: "COCO Acquired Cat-2 / FY24", value: "COCO Acquired Cat-2 / FY24" },
  { label: "COCO Acquired Cat-3 / FY25", value: "COCO Acquired Cat-3 / FY25" },
  { label: "COCO Build Cat-1 / FY23", value: "COCO Build Cat-1 / FY23" },
  { label: "COCO Build Cat-2 / FY24", value: "COCO Build Cat-2 / FY24" },
  { label: "COCO Build Cat-3 / FY25", value: "COCO Build Cat-3 / FY25" },
  { label: "COCO Acquired Cat-4 / FY26", value: "COCO Acquired Cat-4 / FY26" },
  { label: "COCO Build Cat-4 / FY26", value: "COCO Build Cat-4 / FY26" },
];

export const TIER_CITY_LIST = [
  { label: "Metro", value: "Metro" },
  { label: "Non-traditional", value: "Non-traditional" },
  { label: "Tier 1", value: "Tier 1" },
  { label: "Tier 2", value: "Tier 2" },
  { label: "Tier 3", value: "Tier 3" },
];
export const COFFEE_STATUS_LIST = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
export const STORE_OPERATION_LIST = [
  { label: "Regular", value: "REGULAR" },
  { label: "Breakfast", value: "BREAKFAST" },
  { label: "Late night", value: "LATE_NIGHT" },
];
export const LFL_LIST = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
