export const setAllVals = (
  labelKey,
  valueKey,
  value,
  setValList,
  isoptVals,
  isValValArr,
  options
) => {
  if (!Array.isArray(value)) {
    setValList([]);
    return;
  }
  if (isValValArr) {
    const valueMap = value.reduce((uniqueMap, item) => {
      uniqueMap[item] = true;
      return uniqueMap;
    }, {});
    const finalVal = options?.reduce?.((list, item) => {
      const label = labelKey ? item?.[labelKey] : item?.label;
      const value = valueKey ? item?.[valueKey] : item?.value;
      if (valueMap[value]) {
        list.push({ label, value });
      }
      return list;
    }, []);
    setValList(finalVal);
    return;
  }
  if (isoptVals) {
    const newVal = value.map((item) => {
      return { label: item, value: item };
    });
    setValList(newVal);
    return;
  }
  const valList =
    value?.map?.((item) => {
      return {
        label: labelKey ? item?.[labelKey] : item?.label,
        value: valueKey ? item?.[valueKey] : item?.value,
        ...item,
      };
    }) || [];
  setValList(valList);
};

export const convertValue = (val, isoptVals, isValValArr) => {
  if (isValValArr) {
    return val.map((item) => item.value);
  }
  const valMap =
    val?.reduce?.((uniqueMap, item) => {
      uniqueMap[item.value] = item;
      return uniqueMap;
    }, {}) || {};
  const valList = Object.values(valMap);
  if (isoptVals) {
    return valList.map((item) => item.value);
  }
  return valList;
};

export const setAllOpts = (
  isoptVals,
  options,
  setOptList,
  labelKey,
  valueKey
) => {
  if (!Array.isArray(options)) {
    setOptList([]);
    return;
  }

  if (isoptVals) {
    // Options is not a luist, in this case we set the data  as empty arr
    const opts = options.map((item) => {
      return { label: item, value: item };
    });
    setOptList(opts);
    return;
  }
  const cvrtOptions =
    options?.map?.((item) => {
      return {
        label: labelKey ? item?.[labelKey] : item?.label,
        value: valueKey ? item?.[valueKey] : item?.value,
        ...item,
      };
    }) || [];
  setOptList(cvrtOptions);
};
