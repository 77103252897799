/** @format */
const commonPrefix = "/v1/central-service/";
export const REST_URLS = {
  LOGIN: `${commonPrefix}auth/login`,
  REFRESH_TOKEN: `${commonPrefix}auth/refresh-tokens`,
  LOGOUT: `${commonPrefix}auth/logout`,
  GET_ALL_USERS: `${commonPrefix}users`,
  USER_LIMITS: `${commonPrefix}users/user-limits`,
  GET_APPS: `${commonPrefix}admin/get-all-apps`,
  GET_ROLES: `${commonPrefix}admin/get-all-roles`,
  CREATE_ROLE: `${commonPrefix}admin/create-role`,
  GET_ALL_ROLES_AND_APPS: `${commonPrefix}admin/all-role-list-with-apps`,
  CREATE_APP: `${commonPrefix}admin/create-app`,
  CREATE_INTRANET_USER: `${commonPrefix}users`,
  UPDATE_INTRANET_USER: `${commonPrefix}users/`,
  NOTIFICATIONS_UNSEEN: "/v1/central-service/notifications/unseen-count",
  NOTIFICATIONS: "/v1/central-service/notifications",
  NOTIFICATIONS_SEEN: "/v1/central-service/notifications/mark-seen",
  GET_BLOGS: "/v1/central-service/blogs/get-blogs",
  GET_PROJECTS: "/v1/central-service/admin/get-all-apps",
  GET_CATEGORY: "/v1/central-service/blogs/all-blog-categories",
  UPLOAD: "/v1/central-service/blogs/upload-images",
  CREATE_BLOG: "/v1/central-service/blogs",
  UPDATE_BLOG: "/v1/central-service/blogs/update-blog/",
  CREATE_CATEGORY: "/v1/central-service/blogs/create-category",
  AR_DATA: `${commonPrefix}ar/ar-list`,
  ENTITY_SEARCH: `${commonPrefix}ar/search/`,
  APP_NAME_LIST: `${commonPrefix}admin/app-name-list`,
  WRITE_NOTIFICATION: `${commonPrefix}notifications/write-notification`,
  CREATE_USER_ROLE: `${commonPrefix}admin/create-user-role`,
  GET_USER_ROLES: `${commonPrefix}admin/get-all-user-roles`,
  SEARCH_BY_USER_NAME: `${commonPrefix}users/search`,
  STORE_BY_STORE_NUMBER: `${commonPrefix}/stores/by-store-number`,
  DOWNLOAD_USERS: `${commonPrefix}users/download-users`,
  UPDATE_APP: `${commonPrefix}admin/update-app/`,
  GET_DEPARTMENTS: `${commonPrefix}department`,
  CREATE_DEPARTMENT: `${commonPrefix}department`,
  GET_DEPARTMENT_LIST: `${commonPrefix}department/all-department-list`,
  UPDATE_DEPARTMENT: `${commonPrefix}department/`,
  GET_DEPARTMENT_ROLE_LIST: `${commonPrefix}department/all-department-role-list`,
  USER_LIST: `${commonPrefix}users/user-list`,
  NON_CREATE_INVOICE_VENDORS: `${commonPrefix}users/non-created-vendors-list`,
  PREDICT_BREAD: `https://feedback.subway.in:3003/api/PredictBread`,
};
export const COMMON_SUB_ROUTE = "/invoice-tracking";
export const BD_LEAD_ROUTE = "/bd-lead";
