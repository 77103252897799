/** @format */

import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import "./index.scss";
import { invokeApi, HTTP_METHODS } from "../../utils/http-service";
import { REST_URLS } from "../../../common-utilities/utils/end-points/docu-storage";
import { toast } from "react-toastify";
import {
  getDataForAlreadyUploadedDocument,
  getFormConfig,
  uploadDocumenTypeFormatConfig,
} from "./helper";
import { getPayload } from "./form-payload";
import { isFormValid } from "./validations";
import Select from "react-select";
import DocumentPreview from "./document-preview";
import "rsuite/dist/rsuite.min.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { allRegions } from "../../../common-utilities/utils/region";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import CommonBtn from "../../../common-utilities/core/common-button";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";

const sx = {
  input: {
    background: "#fff",
    borderColor: "gray",
  },
};

const UploadDocuments = ({
  id,
  storeType,
  setStoreType,
  setFilters,
  setResId,
  setlistIds,
}) => {
  const [errors, setErrors] = useState({});
  const [isEditingDoc, setIsEditingDoc] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [showForm, setshowForm] = useState(true);
  const [docs, setDocs] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [disableEditing, setDisableEditing] = useState(true);
  const user = getOfflineData(tokenMainKeys.docuStorage, "user");
  useEffect(() => {
    if (storeType || id) {
      setIsEditingDoc(false);
      setFormDetails({});
    }
  }, [storeType, id]);

  const onInputChange = (event, resetForm) => {
    getDataForAlreadyUploadedDocument(
      event,
      storeType,
      id,
      formDetails,
      setFormDetails,
      setIsEditingDoc,
      setDocs,
      setDisableEditing
    );

    let addressDetailsToReset = {};
    if (
      event.target.name === "country" ||
      event.target.name === "leasedCountry"
    ) {
      const allStates = Object.keys(allRegions[event.target.value.value]);
      if (event.target.name === "leasedCountry") {
        addressDetailsToReset = {
          leasedState: null,
          leasedCity: null,
          leasedPincode: "",
        };
      }
      let stateObj = allStates.sort();
      setStates(() =>
        stateObj.map((state) => ({ label: state, value: state }))
      );
    }

    if (event.target.name === "state" || event.target.name === "leasedState") {
      let currentCountry;
      if (event.target.name === "state") {
        currentCountry = formDetails.country;
      } else if (event.target.name === "leasedState") {
        currentCountry = formDetails.leasedCountry;
      }

      let cityObj = (
        allRegions[currentCountry?.value]?.[event.target.value.value] || []
      ).sort();
      setCities(() => cityObj.map((city) => ({ label: city, value: city })));
      if (event.target.name === "leasedState") {
        addressDetailsToReset = { leasedCity: null, leasedPincode: "" };
      }
    }
    if (resetForm) {
      const newFormdetails = {};
      newFormdetails[event.target.name] = event.target.value;
      setFormDetails(newFormdetails);
      setTimeout(() => {
        setshowForm(true);
      }, 100);
      return;
    }
    setFormDetails({
      ...formDetails,
      [event.target.name]: event.target.value,
      ...addressDetailsToReset,
    });
  };
  const saveUpload = () => {
    let payload = getPayload(formDetails, docs, id, storeType);
    const method = isEditingDoc ? HTTP_METHODS.PUT : HTTP_METHODS.POST;
    const url = isEditingDoc
      ? `${HOSTNAME}${REST_URLS.UPDATE_UPLOAD_DOCUMENT}`
      : `${HOSTNAME}${REST_URLS.SAVE_DOCUMENT}`;
    invokeApi(method, url, payload).then((response) => {
      if (response.message) {
        setFormDetails({});
        setDocs([]);
        toast.error(response?.message, { autoClose: 2000 });
      } else {
        setFormDetails({});
        setDocs([]);
        toast.info(
          `Document ${isEditingDoc ? "Updated" : "Uploaded"} Successfully`
        );
        setTimeout(() => {
          setStoreType(null);
          setFilters((prevVal) => ({
            ...prevVal,
            storeType: null,
            type: null,
          }));
          setResId(null);
          setlistIds([]);
        }, 2000);
      }
    });
  };

  const inputElements = (config) => {
    if (config.subForm) {
      return (
        <div
          className={
            config.type === "upload" ? "upload-section form-row" : "form-row"
          }
          key={config.label}
        >
          <span className="label">{config.label}</span>
          <div className="sub-form">
            {config.subForm.map((subConfig) => {
              if (subConfig.type === "select") {
                return (
                  <div>
                    <span className="sub-label">{subConfig.label}</span>
                    <Select
                      placeholder={subConfig.placeholder}
                      options={subConfig.options}
                      isDisabled={!!subConfig.disabled}
                      value={subConfig.value || ""}
                      onChange={(value) => {
                        onInputChange({
                          target: {
                            name: subConfig.name,
                            value: value,
                          },
                        });
                      }}
                    />
                  </div>
                );
              } else if (subConfig.type === "upload") {
                return (
                  <div>
                    <p className="sub-label">{subConfig.label}</p>
                    <DocumentPreview
                      documentLimitationConfig={uploadDocumenTypeFormatConfig}
                      isEditingDoc={isEditingDoc}
                      doNotShowDocsArr={subConfig.doNotShowDocsArr}
                      docs={docs}
                      documentSavingKey={subConfig?.documentSavingKey || ""}
                      documentType={subConfig.uploadType}
                      setDocs={(data) => {
                        onInputChange({
                          target: {
                            name: subConfig.name,
                            value: data,
                          },
                        });
                        setDocs(data);
                      }}
                    />
                  </div>
                );
              } else if (subConfig.type === "date") {
                return (
                  <div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label={subConfig.label}
                        inputFormat="DD/MM/YYYY"
                        value={
                          subConfig.value
                            ? moment(subConfig.value, "DD-MM-YYYY")
                            : null
                        }
                        onChange={(value) => {
                          onInputChange({
                            target: {
                              name: subConfig.name,
                              value: moment(value).format("DD-MM-yyyy"),
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="new-textfield"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                );
              } else {
                return (
                  <>
                    <p className="sub-label">{subConfig.label}</p>
                    <TextField
                      size="small"
                      value={subConfig.value || ""}
                      name={subConfig.name}
                      type={subConfig.type}
                      placeholder={subConfig.placeholder || subConfig.name}
                      sx={sx}
                      onChange={onInputChange}
                      error={subConfig.error}
                      className="new-textfield"
                      helperText={subConfig.error ? "Required" : ""}
                    />
                  </>
                );
              }
            })}
          </div>
        </div>
      );
    }

    return (
      <div
        className={
          config.type === "upload" ? "upload-section form-row" : "form-row"
        }
        key={config.label}
      >
        <span className="label">{config.label}</span>
        {config.type === "select" ? (
          <>
            <AutocompleteComponent
              label="Select"
              options={config.options}
              value={config.value || ""}
              disabled={!!config.disabled}
              onChange={(e, value) => {
                let resetForm = false;
                if (config.name === "type") {
                  setDocs([]);
                  setshowForm(false);
                  resetForm = true;
                }
                onInputChange(
                  {
                    target: {
                      name: config.name,
                      value: value,
                    },
                  },
                  resetForm
                );
              }}
              disableClear={true}
            />
          </>
        ) : config.type === "upload" ? (
          <>
            <DocumentPreview
              documentLimitationConfig={uploadDocumenTypeFormatConfig}
              isEditingDoc={isEditingDoc}
              doNotShowDocsArr={config.doNotShowDocsArr}
              docs={docs}
              documentSavingKey={config?.documentSavingKey || ""}
              documentType={config.uploadType}
              setDocs={(data) => {
                setFormDetails({
                  ...formDetails,
                  [config.name]: data,
                });
                setDocs(data);
              }}
            />
            {config.notUploadCheckBox && (
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={formDetails[config.notUploadKey] || false}
                    onChange={() => {
                      setFormDetails((prevVal) => ({
                        ...prevVal,
                        [config.notUploadKey]:
                          !formDetails[config.notUploadKey],
                      }));
                    }}
                  />
                }
                label="Not Applicable"
              />
            )}
          </>
        ) : config.type === "date" ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={config.label}
              inputFormat="DD/MM/YYYY"
              value={config.value ? moment(config.value, "DD-MM-YYYY") : null}
              onChange={(value) => {
                onInputChange({
                  target: {
                    name: config.name,
                    value: moment(value).format("DD-MM-yyyy"),
                  },
                });
              }}
              renderInput={(params) => (
                <TextField className="new-textfield" size="small" {...params} />
              )}
            />
          </LocalizationProvider>
        ) : (
          <TextField
            size="small"
            value={config.value}
            name={config.name}
            className="new-textfield"
            type={config.type}
            sx={sx}
            onChange={onInputChange}
            error={config.error}
            helperText={config.error ? "Required" : ""}
          />
        )}
      </div>
    );
  };
  return (
    <div className="create-upload-tickets">
      <div>
        <div className="upload-docs-body">
          <div className="create-upload-form">
            {showForm && (
              <>
                {getFormConfig(
                  formDetails,
                  errors,
                  states,
                  cities,
                  storeType,
                  isEditingDoc,
                  docs
                ).map((config) => {
                  return inputElements(config);
                })}
              </>
            )}
          </div>
        </div>
        {user.role !== "READ_ONLY" && (
          <div className="buttons">
            <CommonBtn
              variant="contained"
              color="primary"
              disabled={
                isFormValid(
                  formDetails,
                  docs,
                  storeType,
                  isEditingDoc,
                  disableEditing
                ) || Object.keys(formDetails).length === 0
              }
              onClick={saveUpload}
            >
              Save details
            </CommonBtn>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDocuments;
