import React, { useEffect, useState } from "react";
import "./index.scss";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  cocoAcqKeyNames,
  cocoBuiltKeyNames,
  storeTypes,
} from "../../utils/constants";
import { setProperty } from "../../../common-utilities/utils/misc";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import AutoCompleteMultiSelect from "../../../common-utilities/core/autocomplete-multiselect";
import { getOrderingPlatformList, getPriceCohortList } from "./api";

const Details2 = ({ setDocumentData, documentData }) => {
  const [orderingPlatformList, setOrderingPlatformList] = useState([]);
  const [priceCohortList, setPriceCohortList] = useState([]);

  const isCocoAcq = documentData?.storeType?.value === storeTypes.cocoAcquired;
  const keys = isCocoAcq ? { ...cocoAcqKeyNames } : { ...cocoBuiltKeyNames };
  useEffect(() => {
    getOrderingPlatformList(setOrderingPlatformList);
    getPriceCohortList(setPriceCohortList);
  }, []);
  return (
    <div>
      <Grid container md={12} className="grid-con ">
        <Grid item md={4} xs={12}>
          <div className="input-con">
            <p className="label">{keys.ads}*</p>
            <TextField
              name="ads"
              fullWidth
              size="small"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="ads"
              className="new-textfield"
              value={documentData.ads}
              placeholder="ADS"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">{keys.occupancyPercentage}*</p>
            <TextField
              name="occupancyPercentage"
              fullWidth
              size="small"
              type="number"
              className="new-textfield"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="occupancyPercentage"
              value={documentData.occupancyPercentage}
              placeholder="Occupancy "
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">{keys.deliveryPercentage}*</p>
            <TextField
              fullWidth
              type="number"
              name="deliveryPercentage"
              className="new-textfield"
              size="small"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="deliveryPercentage"
              value={documentData.deliveryPercentage}
              placeholder="Delivery"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          <div className="input-con">
            <p className="label">Payback (in months)</p>
            <TextField
              fullWidth
              name="paybackInMonths"
              type="number"
              size="small"
              className="new-textfield"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="paybackInMonths"
              value={documentData.paybackInMonths}
              placeholder="Payback (in months)"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">{keys.orderingPlatform}</p>
            <AutoCompleteMultiSelect
              value={
                documentData.orderingPlatform
                  ? orderingPlatformList.filter((i) =>
                      documentData.orderingPlatform.includes(i.value)
                    )
                  : []
              }
              onChange={(e, val) => {
                const valuesList = val?.map((ele) => ele.value);
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  orderingPlatform: valuesList,
                }));
              }}
              options={orderingPlatformList}
            />
          </div>
          {isCocoAcq && (
            <div className="input-con">
              <p className="label">{keys.forward1YearEbitdaMultiple}</p>
              <TextField
                fullWidth
                size="small"
                className="new-textfield"
                onChange={(e) => {
                  const { value } = e.target;
                  setProperty(
                    "businessDetails.forward1YearEbitdaMultiple",
                    value,
                    setDocumentData
                  );
                }}
                id="forward1YearEbitdaMultiple"
                value={
                  documentData?.businessDetails?.forward1YearEbitdaMultiple
                }
                placeholder={keys.forward1YearEbitdaMultiple}
                variant="outlined"
                required
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )}

          {isCocoAcq && (
            <div className="input-con">
              <p className="label">{keys.ttmAdjustedEbitdaMultiple}</p>
              <TextField
                fullWidth
                name="businessDetails.ttmAdjustedEbitdaMultiple"
                size="small"
                className="new-textfield"
                onChange={(e) => {
                  const { value } = e.target;
                  setProperty(
                    "businessDetails.ttmAdjustedEbitdaMultiple",
                    value,
                    setDocumentData
                  );
                }}
                id="businessDetails.ttmAdjustedEbitdaMultiple"
                value={documentData?.businessDetails?.ttmAdjustedEbitdaMultiple}
                placeholder={keys.ttmAdjustedEbitdaMultiple}
                variant="outlined"
                required
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          {isCocoAcq && (
            <div className="input-con">
              <p className="label">{keys.adsTtm}*</p>
              <TextField
                name="businessDetails.adsTtm"
                className="new-textfield"
                fullWidth
                type="number"
                size="small"
                onChange={(e) => {
                  const { value } = e.target;
                  setProperty("businessDetails.adsTtm", value, setDocumentData);
                }}
                id="adsTtm"
                value={documentData?.businessDetails?.adsTtm}
                placeholder={keys.adsTtm}
                variant="outlined"
                required
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )}
          <div className="input-con">
            <p className="label">{keys.labourPercentage}*</p>
            <TextField
              name="labourPercentage"
              className="new-textfield"
              fullWidth
              type="number"
              size="small"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="labourPercentage"
              value={documentData.labourPercentage}
              placeholder="Labour"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">{keys.ebitdaPercentage}*</p>
            <TextField
              fullWidth
              name="ebitdaPercentage"
              size="small"
              type="number"
              className="new-textfield"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="ebitdaPercentage"
              value={documentData.ebitdaPercentage}
              placeholder="EBITDA"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          {isCocoAcq && (
            <div className="input-con">
              <p className="label">{keys.ttmEbitdaPercentage}</p>
              <TextField
                fullWidth
                name="businessDetails.ttmEbitdaPercentage"
                size="small"
                type="number"
                className="new-textfield"
                onChange={(e) => {
                  const { value } = e.target;
                  setProperty(
                    "businessDetails.ttmEbitdaPercentage",
                    value,
                    setDocumentData
                  );
                }}
                id="ttmEbitdaPercentage"
                value={documentData?.businessDetails?.ttmEbitdaPercentage}
                placeholder={keys.ttmEbitdaPercentage}
                variant="outlined"
                required
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )}
          <div className="input-con">
            <p className="label">{keys.totalCapex}*</p>
            <TextField
              fullWidth
              name="totalCapex"
              size="small"
              className="new-textfield"
              onChange={(e) => {
                const { id, value } = e.target;
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  [id]: value,
                }));
              }}
              id="totalCapex"
              value={documentData.totalCapex}
              placeholder="Total Capex"
              variant="outlined"
              required
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="input-con">
            <p className="label">{keys.priceCohort}</p>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Price Cohort"
              className="sel-item"
              options={priceCohortList}
              value={
                ![undefined, null, ""].includes(documentData.priceCohort)
                  ? priceCohortList.find(
                      (i) => i.value === documentData.priceCohort
                    )
                  : ""
              }
              onChange={(e, val) => {
                setDocumentData((prevVal) => ({
                  ...prevVal,
                  priceCohort: val?.value,
                }));
              }}
              disableClear={true}
            />
          </div>

          {isCocoAcq && (
            <div className="input-con">
              <p className="label">{keys.grossOfferExtendedA}*</p>
              <TextField
                fullWidth
                name="businessDetails.grossOfferExtendedA"
                size="small"
                type="number"
                className="new-textfield"
                onChange={(e) => {
                  const { value } = e.target;
                  setProperty(
                    "businessDetails.grossOfferExtendedA",
                    value,
                    setDocumentData
                  );
                }}
                id="businessDetails.grossOfferExtendedA"
                value={documentData?.businessDetails?.grossOfferExtendedA}
                placeholder={keys.grossOfferExtendedA}
                variant="outlined"
                required
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Details2;
