import ActionIcons from "../actionIcons";
import { userTypes } from "../../utils/constants";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { selectStoreTypeMap } from "../Store/config";
import { tokenMainKeys } from "../../../../utils/constants";

export const getHeaderConfig = (loadData) => {
  const allValuesMap = selectStoreTypeMap();

  const headerConfig = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Business Type",
      id: "allowedStoreTypes",
      render: (data) => {
        let allStoreTypeValues = [];
        data?.allowedStoreTypes?.forEach((item) => {
          const frontEndLabelForStoreType = allValuesMap[item];
          if (frontEndLabelForStoreType) {
            allStoreTypeValues.push(frontEndLabelForStoreType);
          }
        });
        allStoreTypeValues = allStoreTypeValues.join(", ");
        return <div className="si-cell">{allStoreTypeValues}</div>;
      },
    },
    {
      label: "Actions",
      id: "actions",
      render: (data) => {
        return (
          <div className="department-action-icons">
            <ActionIcons
              type={data.allowedStoreTypes}
              modalType="DEPARTMENT"
              loadData={loadData}
              id={data.id}
              name={data.name}
              departmentRegionData={data.regionData}
            />
          </div>
        );
      },
    },
  ];
  let type = userTypes.STORE_OWNER;
  const usercredentails = getOfflineData(tokenMainKeys.technet, "user");
  if (usercredentails && usercredentails.type) {
    type = usercredentails.type;
  }
  if (type === userTypes.STORE_OWNER) {
    headerConfig.pop();
  }
  return headerConfig;
};
export const getMobileHeaderConfig = () => [
  {
    label: "Name",
    id: "name",
  },
  {
    label: "Business Type",
    id: "allowedStoreTypes",
  },
];
