import { getUserRole } from "../../utils";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { qnetEndPoint } from "../../utils/endpoints";

export const MENU_OPTIONS = {
  ADMIN: ["Dashboard", "Cases", "Reports", "Setup"],
  STORE_OWNER: ["Cases", "Reports"],
  QA: ["Cases", "Reports"],
  DC: ["Cases", "Reports"],
  VENDOR: ["Cases", "Reports"],
  "R&D": ["Dashboard", "Cases", "Reports", "Setup"],
  STORE: ["Cases", "Reports"],
  ACTION_TAKER: ["Cases", "Reports"],
  CREDIT_ISSUER: ["Cases", "Reports"],
  AM: ["Cases", "Reports"],
  USER: ["Cases", "Reports"],
};

const UrlMap = {
  Dashboard: "/",
  Cases: "/cases",
  Reports: "/reports",
  Setup: "/setup",
};

export const SideMenuConfig = (navigate) => {
  const role = getUserRole();
  const roleType = role?.type;

  if (!roleType) {
    navigate("/");
    return;
  }
  const options = MENU_OPTIONS[roleType] || [];
  return (
    options?.map?.((item, index) => {
      const url = UrlMap[item];
      const logo =
        index % 2 === 0 ? (
          <DashboardIcon sx={{ mr: 1 }} />
        ) : (
          <SettingsIcon sx={{ mr: 1 }} />
        );
      return {
        label: item,
        url: `${qnetEndPoint}${url}`,
        logo,
        value: `${qnetEndPoint}${url}`,
      };
    }) || []
  );
};
