/** @format */

import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Store from "../Store";
import Department from "../Department";
import Users from "../Users";
import TicketCategory from "../TicketCategory";
import { getOfflineData } from "../../../common-utilities/utils/offline-services";
import { tokenMainKeys } from "../../../../utils/constants";
import { userTypes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { technetEndPoint } from "../../../common-utilities/utils/end-points/tech-net";
import Tags from "../tags";
import ExternalClientSecret from "../external-client-secret";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Setup = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = getOfflineData(tokenMainKeys.technet, "user");
    if (user?.type !== userTypes.ADMIN) {
      navigate(`${technetEndPoint}/tickets`);
    }
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab label="Store" />
          <Tab label="Department" />
          <Tab label="Users" />
          <Tab label="Category" />
          <Tab label="Tags" />
          <Tab label="External Client Secret" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Store />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Department />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Users />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TicketCategory />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Tags />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ExternalClientSecret />
      </TabPanel>
    </Box>
  );
};

export default Setup;
