import { Autocomplete, TextField, Chip } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { convertValue, setAllOpts, setAllVals } from "./utils";

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e2e2e2; /* Replace with your desired border color */
    }
  }
`;

// isValValArr - This means that the value provided is an array of all the values not labels and values

// useDefaultOpts - For using the default options
const AutoCompleteMultiSelect = ({
  value,
  onChange,
  options,
  placeholder,
  className,
  labelKey,
  valueKey,
  isoptVals,
  useDefaultVal,
  isValValArr,
  useDefaultOpts,
  name = "name",
  fullWidth = false,
}) => {
  const [optList, setOptList] = useState([]);
  const [valList, setValList] = useState([]);

  // When value is a plain array and label and value are the same then we set value list
  useEffect(() => {
    setAllVals(
      labelKey,
      valueKey,
      value,
      setValList,
      isoptVals,
      isValValArr,
      options
    );
  }, [labelKey, valueKey, value, isoptVals, isValValArr, options]);

  useEffect(() => {
    setAllOpts(isoptVals, options, setOptList, labelKey, valueKey);
  }, [isoptVals, options, labelKey, valueKey]);

  return (
    <>
      <StyledAutocomplete
        multiple
        id="size-small-filled-multi"
        fullWidth={fullWidth}
        size="small"
        className={`styled-text-field ${className ? className : ""}`}
        options={labelKey || valueKey || isoptVals ? optList : options}
        value={
          labelKey || valueKey || isoptVals || useDefaultVal || useDefaultOpts
            ? valList
            : value
        }
        onChange={(e, val) => {
          onChange &&
            onChange(
              { ...e, target: { ...e.target, name } },
              convertValue(val, isoptVals, isValValArr)
            );
        }}
        getOptionLabel={(option) => option.label}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={labelKey ? option?.[labelKey] : option?.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={placeholder || ""}
            placeholder=""
          />
        )}
      />
    </>
  );
};

export default AutoCompleteMultiSelect;
