/** @format */

import React, { useState, useEffect } from "react";
import "./index.scss";
import { getHeaderConfig } from "./config";
import Button from "@mui/material/Button";
import CustomModal from "../../../common-utilities/core/modal";
import { invokeApi } from "../../utils/http-service";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import MuiTable from "../../../common-utilities/core/mui-table";
import CommonBtn from "../../../common-utilities/core/common-button";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";

const PriceCohort = () => {
  const [data, setData] = useState({});
  const [addPriceCohort, setAddPriceCohort] = useState(false);
  const [name, setName] = useState("");
  const [del, setDel] = useState(false);
  const [editData, setEditData] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
    paginate: true,
  });
  const addDepartmentFormHandler = () => {
    setAddPriceCohort(true);
  };

  const addNameHandler = (event) => {
    setName(event.target.value);
  };
  const handleClick = (data, type) => {
    switch (type) {
      case "EDIT":
        addDepartmentFormHandler();
        setName(data.name);
        setEditData(data);
        break;
      case "DELETE":
        setDel(true);
        setEditData(data);
        break;
      default:
        break;
    }
  };
  //Add New PriceCohort
  const formSubmitHandler = () => {
    if (!name?.trim()) {
      toast.error("Empty Fields Not Allowed", { autoClose: 2000 });
    } else {
      const payload = {
        name: name,
      };
      if (editData?.id) {
        invokeApi(
          HTTP_METHODS.PUT,
          `${HOSTNAME}${REST_URLS.PRICE_COHORT}/${editData?.id}`,
          payload
        )
          .then((response) => {
            if (response.message) {
              toast.error(response.message);
            } else {
              toast.success("Price Cohort Updated Successfully");
              loadData(filters);
              setName("");
              setAddPriceCohort(false);
              setEditData({});
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        invokeApi(
          HTTP_METHODS.POST,
          `${HOSTNAME}${REST_URLS.PRICE_COHORT}`,
          payload
        )
          .then((response) => {
            if (response.message) {
              toast.error(response.message);
            } else {
              toast.success("Price Cohort Created Successfully");
              loadData(filters);
              setName("");
              setAddPriceCohort(false);
              setEditData({});
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const confirmDelete = () => {
    invokeApi(
      HTTP_METHODS.DELETE,
      `${HOSTNAME}${REST_URLS.PRICE_COHORT}/${editData.id}`
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        if (response) {
          setDel(false);
          setEditData({});
          loadData(filters);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadData = (params) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.PRICE_COHORT}`,
      null,
      params
    )
      .then((response) => {
        if (response?.message) {
          toast.error(response?.message, { autoClose: 2000 });
          return;
        }
        if (response) {
          setData(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  return (
    <>
      <div className="department-container">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            className="button"
            onClick={addDepartmentFormHandler}
          >
            Create Price Cohort
          </Button>
        </Box>

        <MuiTable
          columnsList={getHeaderConfig()}
          dataList={data.results || []}
          filters={filters}
          pageCount={data.totalPages}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            loadData({
              ...filters,
              page,
            });
          }}
          onClick={handleClick}
        ></MuiTable>
      </div>
      {addPriceCohort && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          title={editData?.id ? "Edit Price Cohort" : "New Price Cohort"}
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setAddPriceCohort(false);
            setName("");
            setEditData({});
          }}
        >
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              fullWidth
              id="name"
              label="Name"
              required
              multiline
              sx={{ my: 1 }}
              onChange={addNameHandler}
              value={name}
              size="small"
              className="new-textfield"
            />

            <Stack
              alignItems="flex-end"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
              sx={{ my: 2 }}
            >
              <CommonBtn
                variant="contained"
                color="primary"
                onClick={formSubmitHandler}
                fullWidth
              >
                {editData?.id ? "Update" : "Create"}
              </CommonBtn>
              <CommonBtn
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAddPriceCohort(false);
                  setName("");
                  setEditData({});
                }}
                fullWidth
              >
                Cancel
              </CommonBtn>
            </Stack>
          </Box>
        </CustomModal>
      )}
      {del && (
        <CustomModal
          title="Confirm Delete"
          classesToPreventClosing={["MuiAutocomplete-option"]}
          contentClassName={{ headerBackgroundColor: "#008938" }}
          onClose={() => {
            setDel(false);
            setEditData({});
          }}
        >
          <h3 className="d-flex">Are you sure ?</h3>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            spacing={2}
          >
            <CommonBtn
              variant="outlined"
              size="medium"
              onClick={() => {
                setDel(false);
                setEditData({});
              }}
            >
              Cancel
            </CommonBtn>
            <CommonBtn
              size="medium"
              variant="contained"
              color="error"
              onClick={confirmDelete}
            >
              Delete
            </CommonBtn>
          </Stack>
        </CustomModal>
      )}
    </>
  );
};

export default PriceCohort;
