/** @format */

export const DATE_FORMATS = {
  "DD.MM.YYYY": "DD.MM.YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "DD.MM.YYYY hh:MM": "DD.MM.YYYY hh:MM",
  "DD MMM YY hh:MM": "DD MMM YY hh:MM",
  "MMM DD YYYY hh:MM": "MMM DD YYYY hh:MM",
  "YYYY-MM-DD": "YYYY-MM-DD",
};

export const userTypes = {
  admin: "ADMIN",
  user: "USER",
};

export const storeTypes = {
  cocoBuild: "COCO BUILT",
  cocoAcquired: "COCO ACQUIRED",
};

export const userStates = {
  isProcessing: "Checking",
};

export const allStores = Object.values(storeTypes);

export const storeList = allStores.map((item) => {
  return { label: item, value: item };
});

export const cocoAcqKeyNames = {
  competitionAndNearbyRestaurantSales: "Monthly P&L - TTM Vs Forward 1 yr",
  ads: "ADS - Fwd Yr1",
  labourPercentage: "Fwd 1 Yr Labour(%)",
  occupancyPercentage: "Fwd 1 Yr Occupancy(%)",
  ebitdaPercentage: "Fwd 1 Yr EBITDA(%)",
  deliveryPercentage: "Fwd 1 Yr Delivery(%)",
  adsTtm: "ADS - TTM",
  ttmEbitdaPercentage: "TTM EBITDA %",
  forward1YearEbitdaMultiple: "Forward 1 yr EBITDA Multiple",
  grossOfferExtendedA: "Total Gross Offer (INR lacs)",
  ttmAdjustedEbitdaMultiple: "TTM Adjusted EBITDA multiple",
  totalCapex: "Total Acquistion Cost (INR lacs)",
  priceCohort: "Price Cohort",
  orderingPlatform: "Ordering Platform",
};

export const cocoBuiltKeyNames = {
  competitionAndNearbyRestaurantSales:
    "Competition and Nearby restaurant sales",
  ads: "ADS (Avg Daily Sales)",
  labourPercentage: "Labour(%)",
  occupancyPercentage: "Occupancy(%)",
  ebitdaPercentage: "EBITDA(%)",
  deliveryPercentage: "Delivery(%)",
  totalCapex: "Total Capex (INR lacs)",
  priceCohort: "Price Cohort",
  orderingPlatform: "Ordering Platform",
};
