import { pick } from "../../../common-utilities/utils";
import { HOSTNAME } from "../../../common-utilities/utils/end-points";
import { REST_URLS } from "../../../common-utilities/utils/end-points/cf-approval";
import { HTTP_METHODS } from "../../../common-utilities/utils/http-service";
import { getLabelsAndValuesForSelect } from "../../utils";
import { invokeApi } from "../../utils/http-service";

export const getFieldList = (filter, setDataFunc) => {
  const finalFilter = pick(
    filter,
    ["fieldName", "fieldValue", "storeType"],
    true
  );
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.FIELD_NAME}`,
    null,
    finalFilter
  )
    .then((res) => {
      if (res?.message) {
        setDataFunc([]);
        return;
      }
      const selectVals = getLabelsAndValuesForSelect(res, res, "value", false);
      setDataFunc(selectVals);
    })
    .catch((err) => {
      setDataFunc([]);
    });
};

export const getOrderingPlatformList = (setOrderingList) => {
  invokeApi(
    HTTP_METHODS.GET,
    `${HOSTNAME}${REST_URLS.ORDERING_PLATFORM}`,
    null,
    { paginate: false }
  )
    .then((response) => {
      if (!response.message) {
        const results = response?.map((ele) => ({
          label: ele.name,
          value: ele.name,
        }));
        setOrderingList(results);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPriceCohortList = (setPriceCohortList) => {
  invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PRICE_COHORT}`, null, {
    paginate: false,
  })
    .then((response) => {
      if (!response.message) {
        const results = response?.map((ele) => ({
          label: ele.name,
          value: ele.name,
        }));
        setPriceCohortList(results);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
