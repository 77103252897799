import React from "react";
import "./index.scss";
import CustomLineChart from "./line-chart";
import { Grid, Typography } from "@mui/material";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import { chartCategory } from "./helper";
import { convertToCamelCaseFromUnderScore, getUserRole } from "../../utils";
const Overview = ({ data = {} }) => {
  const {
    byEntities,
    mttc,
    mttr,
    top5CaseStatuses = [],
    topIssues = {},
    trendData = {},
  } = data;

  const {
    overallMonthWiseChartData,
    overallMonthWiseByDCChartData,
    overallMonthWiseByStoreChartData,
    overallMonthWiseByVendorChartData,
  } = trendData || {};

  const {
    store = {},
    creditNotesStore = { count: 0 },
    creditNotesVendor = { count: 0 },
    faultAccepted = { count: 0 },
    faultRejected = { count: 0 },
    unresolvedTicketsByX = 0,
  } = topIssues || {};

  const {
    admin = { count: 0 },
    complaintType = { count: 0 },
    dc = { count: 0 },
    byVendor = { count: 0 },
    byStore = { count: 0 },
  } = byEntities || {};

  const roleDetails = getUserRole();

  return (
    <div className="p10 overview-Container">
      <Grid container spacing={0}>
        <Grid item md={6} xs={12}>
          <div className="heading">Top Issues</div>
          <Grid container spacing={0} className="m0">
            <Grid item xs={6} className="card-base ">
              <div className="card-conent">
                <OfflineBoltOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#982698" }}
                />
                <span className="icon-span">Store Case</span>
                <span className={"float-right bolder"}>{store.count}</span>
              </div>
            </Grid>
            <Grid item xs={6} className="card-base">
              <div className="card-conent">
                <FlareOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#0646c8" }}
                />
                <span className="icon-span">Credit Notes Store</span>
                <span className={"float-right bolder"}>
                  {creditNotesStore.count}
                </span>
              </div>
            </Grid>
            <Grid item xs={6} className="card-base">
              <div className="card-conent">
                <ImportContactsOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#00a9f4" }}
                />
                <span className="icon-span">Credit Notes Vendor</span>
                <span className={"float-right bolder"}>
                  {creditNotesVendor.count}
                </span>
              </div>
            </Grid>
            <Grid item xs={6} className="card-base">
              <div className="card-conent">
                <ImportContactsOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#00a9f4" }}
                />
                <span className="icon-span">Fault Accepted</span>
                <span className={"float-right bolder"}>
                  {faultAccepted.count}
                </span>
              </div>
            </Grid>
            <Grid item xs={6} className="card-base">
              <div className="card-conent">
                <ImportContactsOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#00a9f4" }}
                />
                <span className="icon-span">Fault Rejected</span>
                <span className={"float-right bolder"}>
                  {faultRejected.count}
                </span>
              </div>
            </Grid>
            <Grid item xs={6} className="card-base">
              <div className="card-conent">
                <ImportContactsOutlinedIcon
                  className="plainIcon"
                  style={{ color: "#00a9f4" }}
                />
                <span className="icon-span">
                  Unresolved for more than 15 days
                </span>
                <span className={"float-right bolder"}>
                  {unresolvedTicketsByX}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={0}>
            <Grid item md={12} xs={12}>
              <div className="heading">Top Issue Status</div>
              <Grid container spacing={0}>
                {top5CaseStatuses.map((record) => {
                  return (
                    <Grid item xs={6} className="card-base">
                      <div className="card-conent">
                        <OfflineBoltOutlinedIcon
                          className="plainIcon"
                          style={{ color: "#982698" }}
                        />
                        <span className="icon-span">
                          {convertToCamelCaseFromUnderScore(
                            record["_id"] || ""
                          )}
                        </span>
                        <span className={"float-right bolder"}>
                          {record?.count}
                        </span>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item md={12} xs={12}>
              <div className="heading">Top Issue By Entity</div>
              <Grid container spacing={0}>
                <Grid item xs={4} className="card-base">
                  <div className="card-conent">
                    <OfflineBoltOutlinedIcon
                      className="plainIcon"
                      style={{ color: "#982698" }}
                    />
                    <span className="icon-span">Issues By admin</span>
                    <span className={"float-right bolder"}>{admin?.count}</span>
                  </div>
                </Grid>
                <Grid item xs={4} className="card-base">
                  <div className="card-conent">
                    <OfflineBoltOutlinedIcon
                      className="plainIcon"
                      style={{ color: "#982698" }}
                    />
                    <span className="icon-span">Issues By Store</span>
                    <span className={"float-right bolder"}>
                      {byStore?.count}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={4} className="card-base">
                  <div className="card-conent">
                    <OfflineBoltOutlinedIcon
                      className="plainIcon"
                      style={{ color: "#982698" }}
                    />
                    <span className="icon-span">Issues By Complaint Type</span>
                    <span className={"float-right bolder"}>
                      {complaintType?.count}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={4} className="card-base">
                  <div className="card-conent">
                    <OfflineBoltOutlinedIcon
                      className="plainIcon"
                      style={{ color: "#982698" }}
                    />
                    <span className="icon-span">Issues By DC</span>
                    <span className={"float-right bolder"}>{dc?.count}</span>
                  </div>
                </Grid>
                <Grid item xs={4} className="card-base">
                  <div className="card-conent">
                    <OfflineBoltOutlinedIcon
                      className="plainIcon"
                      style={{ color: "#982698" }}
                    />
                    <span className="icon-span">Issues By vendor</span>
                    <span className={"float-right bolder"}>
                      {byVendor?.count}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          {Object.keys(trendData).length > 0 ? (
            <CustomLineChart
              title={"Cases Raised"}
              data={[
                {
                  name: "Month Chart Raised",
                  data: overallMonthWiseChartData,
                },
                {
                  name: "DC Chart",
                  data: overallMonthWiseByDCChartData,
                },
                {
                  name: "Store Chart",
                  data: overallMonthWiseByStoreChartData,
                },
                {
                  name: "Vendor Chart",
                  data: overallMonthWiseByVendorChartData,
                },
              ]}
              xDataKey="Tickets_Raised_Each_Month"
              categories={chartCategory}
            />
          ) : (
            <Typography>No Data</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
